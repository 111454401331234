import { colorMixer } from "./utils";

export const getAvailableLanguages = () => {
    return [
        { code: 'std_arabic', name: 'Arabic' },
        { code: 'cantonese', name: 'Cantonese' },
        { code: 'catalan', name: 'Catalan' },
        { code: 'dutch', name: 'Dutch' },
        { code: 'filipino', name: 'Filipino' },
        { code: 'french', name: 'French' },
        { code: 'german', name: 'German' },
        { code: 'hindi', name: 'Hindi' },
        { code: 'indonesian', name: 'Indonesian' },
        { code: 'japanese', name: 'Japanese' },
        { code: 'khmer', name: 'Khmer' },
        { code: 'korean', name: 'Korean' },
        { code: 'malaysian', name: 'Malay' },
        { code: 'mandarin', name: 'Mandarin' },
        { code: 'polish', name: 'Polish' },
        { code: 'bra_portuguese', name: 'Portuguese, Brazilian' },
        { code: 'eur_portuguese', name: 'Portuguese, European' },
        { code: 'russian', name: 'Russian' },
        { code: 'eur_spanish', name: 'Spanish, European' },
        { code: 'mex_spanish', name: 'Spanish, Mexican' },
        { code: 'thai', name: 'Thai' },
        { code: 'turkish', name: 'Turkish' },
        { code: 'ukrainian', name: 'Ukrainian' },
        { code: 'vietnamese', name: 'Vietnamese, Northern' },
        { code: 'sth_vietnamese', name: 'Vietnamese, Southern' }]
}

export const createTokens = (tokenString) => {
    var parts = tokenString.split("|");
    var tokens = [];
    var lastToken = {};
    var rememberPunctuation = undefined;
    parts.forEach(part => {
        var newToken = {}
        if (part.startsWith("°")) {
            lastToken.after = part.slice(1).replace(" ", "\u00a0");
        } else if (part.startsWith("^")) {
            rememberPunctuation = part.slice(1);
        } else {
            if (part.includes("{") && !part.startsWith("{")) {
                var wbwParts = part.split("{");
                part = wbwParts[0];
                var tags = wbwParts[1].slice(0, -1).split("\.");
                newToken.tags = tags;
            }
            newToken.text = part;
            newToken.before = rememberPunctuation;
            rememberPunctuation = undefined;
            tokens.push(newToken);
            lastToken = newToken;
        }
    });
    return tokens;
}


/**
 * tokens:
 * ° - punctuation following a token
 * ^ - punctuation leading a token (e.g., Spanish question mark)
 */
export const getData = (language) => {
    const base1 = (definition, pos) => {
        var extra = "";
        if (definition.startsWith("*")) {
            definition = definition.slice(1);
            extra = " italic";
        }
        return <>
            <div className="tooltip-title">{pos}</div>
            <div className={"tooltip-text" + extra}>{definition}</div></>
    }
    const base2 = (definition, base, baseTranslit, pos) => {
        return <>
            <div className="tooltip-title">{pos}</div>
            <div className="tooltip-text">{definition}</div>
            <div>➤ <span className="tooltip-base-form">{base}</span><span className="tooltip-base-translit">{"(" + baseTranslit + ")"}</span></div>
        </>
    }

    const base3 = (definition, base, pos) => {
        var extra = "";
        if (definition.startsWith("*")) {
            definition = definition.slice(1);
            extra = " italic";
        }
        return <>
            <div className="tooltip-title">{pos}</div>
            <div className={"tooltip-text" + extra}>{definition}</div>
            <div>➤ <span className={"tooltip-title"}>{base}</span></div>
        </>
    }

    const baseHanNoBase = (definition, hanzi, pos, big = false) => {
        var extra = "";
        if (definition.startsWith("*")) {
            definition = definition.slice(1);
            extra = " italic";
        }
        return <>
            <div className="tooltip-title">{pos}</div>
            <div className={"tooltip-text" + extra}>{definition}</div>
            {hanzi !== "" ? <div>➤ <span className={"tooltip-base-" + (big ? "form" : "translit")}>{hanzi}</span></div> : <></>}
        </>
    }

    const baseHan = (definition, base, baseTranslit, hanzi, pos) => {
        var extra = "";
        if (definition.startsWith("*")) {
            definition = definition.slice(1);
            extra = " italic";
        }
        var afterBase = baseTranslit;
        if (hanzi != "") {
            afterBase = hanzi + " / " + afterBase;
        }
        return <>
            <div className="tooltip-title">{pos}</div>
            <div className={"tooltip-text" + extra}>{definition}</div>
            <div>➤ <span className="tooltip-base-translit">{base}</span><span className="tooltip-base-translit">{"(" + afterBase + ")"}</span></div>
        </>
    }

    const araTranslit = (versions) => {
        return <>
            <span className="tooltip-small-title">Lingora: </span><span className="tooltip-text">{versions[0]}</span><br />
            <span className="tooltip-small-title">Encycl. of Islam: </span><span className="tooltip-text">{versions[1]}</span><br />
            <span className="tooltip-small-title">ISO: </span><span className="tooltip-text">{versions[2]}</span><br />
            <span className="tooltip-small-title">Spelling: </span><span className="tooltip-text">{versions[3]}</span>
        </>;
    }

    const yueTranslit = (versions) => {
        return <>
            <span className="tooltip-small-title">Jyutping: </span><span className="tooltip-text">{versions[0]}</span><br />
            <span className="tooltip-small-title">Yale: </span><span className="tooltip-text">{versions[1]}</span><br />
            <span className="tooltip-small-title">Cantonese Pinyin: </span><span className="tooltip-text">{versions[2]}</span><br />
        </>;
    }

    const hinTranslit = (versions) => {
        return <>
            <span className="tooltip-small-title">IAST: </span><span className="tooltip-text">{versions[0]}</span><br />
            <span className="tooltip-small-title">Hunterian: </span><span className="tooltip-text">{versions[1]}</span><br />
            <span className="tooltip-small-title">Spelling: </span><span className="tooltip-text">{versions[2]}</span><br />
            <span className="tooltip-small-title">IPA: </span><span className="tooltip-text">{versions[3]}</span><br />
        </>;
    }

    const japTranslit = (versions) => {
        return <>
            <span className="tooltip-small-title">Hepburn: </span><span className="tooltip-text">{versions[0]}</span><br />
            <span className="tooltip-small-title">Kunrei-Shiki: </span><span className="tooltip-text">{versions[1]}</span><br />
            <span className="tooltip-small-title">Hiragana: </span><span className="tooltip-text">{versions[2]}</span><br />
            <span className="tooltip-small-title">Katakana: </span><span className="tooltip-text">{versions[3]}</span><br />
        </>;
    }

    const khmTranslit = (versions) => {
        return <>
            <span className="tooltip-small-title">Wiktionary: </span><span className="tooltip-text">{versions[0]}</span><br />
            <span className="tooltip-small-title">Simplified: </span><span className="tooltip-text">{versions[1]}</span><br />
            <span className="tooltip-small-title">Spelling: </span><span className="tooltip-text">{versions[2]}</span><br />
            <span className="tooltip-small-title">IPA: </span><span className="tooltip-text">{versions[3]}</span><br />
        </>
    }

    const korTranslit = (versions) => {
        return <>
            <span className="tooltip-small-title">Lingora: </span><span className="tooltip-text">{versions[0]}</span><br />
            <span className="tooltip-small-title">Revised Romanization: </span><span className="tooltip-text">{versions[1]}</span><br />
            <span className="tooltip-small-title">McCune-Reischauer: </span><span className="tooltip-text">{versions[2]}</span><br />
        </>
    }

    const chnTranslit = (versions) => {
        return <>
            <span className="tooltip-small-title">Pinyin: </span><span className="tooltip-text">{versions[0]}</span><br />
            <span className="tooltip-small-title">Zhuyin: </span><span className="tooltip-text">{versions[1]}</span><br />
            <span className="tooltip-small-title">Wade-Giles: </span><span className="tooltip-text">{versions[2]}</span><br />
            <span className="tooltip-small-title">IPA: </span><span className="tooltip-text">{versions[3]}</span><br />
        </>
    }

    const rusTranslit = (bgn, scholarly) => {
        return <>
            <span className="tooltip-small-title">BGN/PCGN: </span><span className="tooltip-text">{bgn}</span><br />
            <span className="tooltip-small-title">Scholarly: </span><span className="tooltip-text">{scholarly}</span><br />
        </>
    }

    const thaTranslit = (lingora, rtgs, ipa) => {
        return <>
            <span className="tooltip-small-title">Lingora: </span><span className="tooltip-text">{lingora}</span><br />
            <span className="tooltip-small-title">RTGS: </span><span className="tooltip-text">{rtgs}</span><br />
            <span className="tooltip-small-title">IPA: </span><span className="tooltip-text">{ipa}</span><br />
        </>
    }

    const ukrTranslit = (bgn, national) => {
        return <>
            <span className="tooltip-small-title">BGN/PCGN: </span><span className="tooltip-text">{bgn}</span><br />
            <span className="tooltip-small-title">National: </span><span className="tooltip-text">{national}</span><br />
        </>
    }

    const grammar1 = (text) => {
        return <div className="tooltip-text">{text}</div>
    }

    switch (language) {
        case "std_arabic":
            return {
                english: "I have two children, a son and a daughter.",
                words: [
                    { name: "Arabic", isRTL: true, size: "3.2em", tokens: "لَدَيَّ|° |طِفْلَاْنِ|°،\u200f |اِبْنٌ|° |وَ|° |ابْنَةٌ|°.\u200f" },
                    { name: "Transliteration", size: "1.8em", tokens: "lạdạyyạ|° |ṭịflānị|°, |ibnụṇ|° |wā|bnạẗụṇ|°." },
                    { name: "Word-by-Word", size: "1.8em", tokens: "with.me|° |children{NOM.DU}|°, |son{NOM}|° |and|° |daughter{NOM}|°." },
                ],
                tooltipData: [
                    [base2("at; near; with (expresses 'to have')", "لَدَى", "ladā", "PREPOSITION"),
                    base2("child", "طِفْل", "ṭifl", "NOUN"), base2("son; descendant", "اِبْن", "ibn", "NOUN"),
                    base2("and; but; while", "وَ", "wa", "CONJUNCTION"), base2("daughter", "اِبْنَة", "ibna", "NOUN")],
                    [araTranslit(["lạdạyyạ", "ladayya", "ladayya", "ldy"]), araTranslit(["ṭịflānị", "ṭiflāni", "ṭiflāni", "ṭflan"]), araTranslit(["ibnụṇ", "ibnun", "ibnun", "abn"]), araTranslit(["wā", "wā", "wā", "w"]),
                    araTranslit(["(ị)bnạẗụṇ", "(i)bnatun", "(i)bnaẗun", "abnẗ"])],
                    [null, [grammar1("Nominative ('Subject') Form"), grammar1("Dual Form (two of sth.)")], [grammar1("Nominative ('Subject') Form")], null, [grammar1("Nominative ('Subject') Form")]]
                ]
            }
        case "cantonese":
            return {
                english: "I have a dog and a cat. They are friends.",
                words: [
                    { name: "Cantonese", size: "2.5em", tokens: "我|有|一|隻|狗|同埋|一|隻|貓|°。|佢哋|係|好|朋友|°。" },
                    { name: "Transliteration", size: "1.8em", tokens: "ngóh|° |yáuh|° |yāt|° |jek|° |gáu|° |tùhng·màaih|° |yāt|° |jek|° |māau|°。|kéuih·deih|° |haih|° |hóu|° |pàhng·yáuh|°。" },
                    { name: "Word-by-Word", size: "2em", tokens: "I|° |have|° |a|° |{CL}|° |dog|° |and|° |a|° |{CL}|° |cat|°。|they|° |are|° |good|° |friend|°。" }
                ],
                tooltipData: [
                    [base1("I; me; my", "PRONOUN"), base1("to have; to be there", "VERB"), base1("a, an; one", "NUMERAL"), base1("*classifier for small animals etc.", "CLASSIFIER"), base1("dog", "NOUN"), base1("and; moreover", "COMJUNCTION"), base1("a, an; one", "NUMERAL"), base1("*classifier for small animals etc.", "CLASSIFIER"), base1("cat", "NOUN"), base1("they; their; them", "PRONOUN"), base1("to be", "VERB"), base1("good", "ADJECTIVE"), base1("friend", "NOUN")],
                    [yueTranslit(["ngo5", "ngóh", "ngo5"]), yueTranslit(["jau5", "yáuh", "jau5"]),
                    yueTranslit(["jat1", "yāt", "jat7"]), yueTranslit(["zek3", "jek", "dzek8"]),
                    yueTranslit(["gau2", "gáu", "gau2"]), yueTranslit(["tung4·maai4", "tùhng·màaih", "tung4·maai4"]),
                    yueTranslit(["jat1", "yāt", "jat7"]), yueTranslit(["zek3", "jek", "dzek8"]),
                    yueTranslit(["maau1", "māau", "maau1"]), yueTranslit(["keoi5·dei6", "kéuih·deih", "koey5·dei6"]),
                    yueTranslit(["hai6", "haih", "hai6"]), yueTranslit(["hou2", "hóu", "hou2"]),
                    yueTranslit(["pang4·jau5", "pàhng·yáuh", "pang4·jau5"])],
                    [null, null, null, [grammar1("Classifier / Counting Word")], null, null, null, [grammar1("Classifier / Counting Word")], null, null, null, null, null],
                ]
            }
        case "catalan":
            return {
                english: "Learning a new language is very hard.",
                words: [
                    { name: "Catalan", size: "2.5em", tokens: "Aprendre|° |un|° |nou|° |idioma|° |és|° |molt|° |difícil|°." },
                    { name: "Word-by-Word", size: "1.8em", tokens: "learning{INF}|° |a{M}|° |new{M}|° |language|° |is|° |very|° |difficult|°." },
                ],
                tooltipData: [
                    [base3("to learn", "aprendre", "VERB"), base3("a; an; one", "un / una", "VERB"), base3("new", "nou / nova", "VERB"), base1("language", "VERB"), base3("to be", "ser", "VERB"), base1("very; quite", "ADVERB"), base1("difficult", "ADJECTIVE")],
                    [[grammar1("Infinitive (Dictionary) Form")], [grammar1("Masculine Form")], [grammar1("Masculine Form")], null, null, null, null]
                ]
            }
        case "dutch":
            return {
                english: "These flowers have a beautiful color.",
                words: [
                    { name: "Catalan", size: "2.5em", tokens: "Deze|° |bloemen|° |hebben|° |een|° |prachtige|° |kleur|°." },
                    { name: "Word-by-Word", size: "1.8em", tokens: "these|° |flowers|° |have{PL}|° |a|° |beautiful{COMM}|° |color|°." },
                ],
                tooltipData: [
                    [base1("these (here)", "DETERMINER"), base3("flower", "bloem", "NOUN"), base3("to have", "hebben", "VERB"), base3("a; an", "een / eene", "ARTICLE"), base3("beautiful; marvelous", "prachtig", "ADJECTIVE"), base1("color", "NOUN")],
                    [null, null, [grammar1("Plural Form")], null, [grammar1("Common Gender (Masculine or Feminine)")], null]
                ]
            }
        case "filipino":
            return {
                english: "My parents work in a bank.",
                words: [
                    { name: "Filipino", size: "2.5em", tokens: "Ang|° |mga|° |magulang|° |ko|° |ay|° |nagtatrabaho|° |sa|° |isa|ng|° |bangko|°." },
                    { name: "Word-by-Word", size: "1.8em", tokens: "the|° |{PL}|° |parent|° |my|° |{INV}|° |are.working|° |at|° |one|° |of|° |bank|°." },
                ],
                tooltipData: [
                    [base1("*marks the direct case (often 'the')", "ARTICLE"), base1("*plural marker before a noun", "PARTICLE"), base1("parent", "NOUN"), base1("me; my (indirect case)", "PRONOUN"), base1("*used to move the predicate to the end of the sentence", "PARTICLE"), base3("to work", "magtrabaho", "VERB"),
                    base1("in; at; on; to", "PREPOSITION"), base1("a; an; one", "NUMERAL"), base1("*connects numbers with nouns ('of')", "LIGATURE"), base1("bank", "NOUN")],
                    [null, [grammar1("Plural Marker")], null, null, [grammar1("Inversion Marker (switches the positions of subject and predicate)")], null, null, null, null, null]
                ]
            }
        case "french":
            return {
                english: "Please bring another bottle of wine.",
                words: [
                    { name: "French", size: "2.5em", tokens: "Ramène|°-|moi|° |une|° |autre|° |bouteille|° |de|° |vin|°, |s|°'|il|° |vous|° |plaît|°." },
                    { name: "Word-by-Word", size: "1.8em", tokens: "bring.again{IMP}|°-|me|° |a{F}|° |other|° |bottle|° |of|° |wine|°, |if|°'|it|° |you{POL}|° |pleases|°." },
                ],
                tooltipData: [
                    [base3("to bring; to get; to take", "ramener", "VERB"), base3("I", "je", "PRONOUN"), base3("a; an (feminine)", "un / une", "ARTICLE"), base1("other; another", "ADJECTIVE"), base1("bottle", "NOUN"), base1("of; from; out of", "PREPOSITION"), base1("wine", "NOUN"), base1("if; whether", "CONJUNCTION"), base1("he; it", "PRONOUN"), base1("you (polite)", "PRONOUN"), base3("to please; to appeal to", "plaire", "VERB")],
                    [[grammar1("Imperative Mood (order or request)")], null, [grammar1("Feminine Form")], null, null, null, null, null, null, [grammar1("Polite Form of 'you'")], null]
                ]
            }
        case "german":
            return {
                english: "The beautiful photo hangs on the wall.",
                words: [
                    { name: "German", size: "2.5em", tokens: "Das|° |schöne|° |Foto|° |hängt|° |an|° |der|° |Wand|°." },
                    { name: "Word-by-Word", size: "1.8em", tokens: "the{N}|° |beautiful{N}|° |photo|° |hangs|° |on|° |the{F.DAT}|° |wall{DAT}|°." },
                ],
                tooltipData: [
                    [base3("the (neuter)", "das", "ARTICLE"), base3("beautiful; pretty", "schön", "ADJECTIVE"), base1("photo", "NOUN"), base3("to hang", "hängen", "VERB"), base1("at; on", "PREPOSITION"), base3("the (feminine)", "die", "ARTICLE"), base1("wall", "NOUN")],
                    [[grammar1("Neuter Gender")], [grammar1("Neuter Gender")], null, null, null, [grammar1("Feminine Gender"), grammar1("Dative Case (indirect object)")], [grammar1("Dative Case (indirect object)")]]
                ]
            }
        case "hindi":
            return {
                english: "What is that? - That is an old radio.",
                words: [
                    { name: "Hindi", size: "3em", tokens: "वो|° |क्या|° |है|°? |^- |वो|° |एक|° |पुराना|° |रेडियो|° |है|°।" },
                    { name: "Transliteration", size: "1.8em", tokens: "vo|° |kyā|° |hai|°? |^- |vo|° |ek|° |purānā|° |reḍiyo|° |hai|°।" },
                    { name: "Word-by-Word", size: "2.2em", tokens: "that|° |what|° |is|°? |^- |that|° |a|° |old{M}|° |radio|° |is|°।" }
                ],
                tooltipData: [
                    [base1("that; those", "PRONOUN"), base1("what", "PRONOUN"), base2("to be", "होना", "honā", "VERB"), base1("that; those", "PRONOUN"), base1("a; an; one", "NUMERAL"), base1("old (of things)", "ADJECTIVE"), base1("radio", "NOUN"), base2("to be", "होना", "honā", "VERB")],
                    [hinTranslit(["vo", "vo", "vo", "ʋoː"]), hinTranslit(["kyā", "kya", "k̖yā", "kjɑː"]), hinTranslit(["hai", "hai", "hai", "ɦɛː"]),
                    hinTranslit(["vo", "vo", "vo", "ʋoː"]), hinTranslit(["ek", "ek", "ek", "eːk"]), hinTranslit(["purānā", "puraana", "purānā", "pʊɾɑːnɑː"]), hinTranslit(["reḍiyo", "rediyo", "reḍiyo", "ɾeːɖɪjoː"]), hinTranslit(["hai", "hai", "hai", "ɦɛː"])],
                    [null, null, null, null, null, [grammar1("Masculine Form")], null, null]
                ]
            }
        case "indonesian":
            return {
                english: "I can't find my glasses.",
                words: [
                    { name: "Indonesian", size: "2.5em", tokens: "Aku|° |tidak|° |bisa|° |menemukan|° |kacamata|ku|°." },
                    { name: "Word-by-Word", size: "1.8em", tokens: "I|° |not|° |can|° |find{TR}|° |glasses|° |my|°." },
                ], tooltipData: [
                    [base1("I (familiar)", "PRONOUN"), base1("no; not", "ADVERB"), base1("to be able to", "VERB"), base3("to find: to meet", "temu", "VERB"), base1("glasses", "NOUN"), base3("my (familiar)", "aku", "PRONOUN")],
                    [null, null, null, [grammar1("Transitive Suffix '-kan'")], null, null]
                ]
            }
        case "japanese":
            return {
                english: "They don't see it, but they hear it.",
                words: [
                    { name: "Japanese", size: "2.5em", tokens: "彼ら|に|は|見えて|いません|が|°、|聞こえて|います|°。" },
                    { name: "Transliteration", size: "1.8em", tokens: "karera|° |ni|° |wa|° |miete|° |imasen|° |ga|°、|kikoete|° |imasu|°。" },
                    { name: "Word-by-Word", size: "2em", tokens: "they|° |by|° |{TOP}|° |be.seen{CONN}|° |isn't{PROG}|° |but|°、|be.heard{TE}|° |is{PROG}|°。" }
                ],
                tooltipData: [
                    [base1("they", "PRONOUN"), base1("*follows passive voice target ('by')", "PARTICLE"), base1("*topic marker", "PARTICLE"), base3("to be seen; to be visible", "見える", "VERB"), base3("*indicates on-going action", "いる", "VERB"),
                    base1("but; however", "PARTICLE"), base3("to be heard", "聞こえる", "VERB"), base3("*indicates on-going action", "いる", "VERB")],
                    [japTranslit(["karera", "karera", "かれら", "カレラ"]), japTranslit(["ni", "ni", "に", "ニ"]), japTranslit(["wa", "wa", "は", "ハ"]), japTranslit(["miete", "miete", "みえて", "ミエテ"]), japTranslit(["imasen", "imasen", "いません", "イマセン"]), japTranslit(["ga", "ga", "が", "ガ"]), japTranslit(["kikoete", "kikoete", "きこえて", "キコエテ"]), japTranslit(["imasu", "imasu", "います", "イマス"])],
                    [null, null, [grammar1("Topic Marker")], [grammar1("Connective 'te'-Form")], [grammar1("Progressive Tense")], null, [grammar1("Connective 'te'-Form")], [grammar1("Progressive Tense")]]
                ]
            }
        case "khmer":
            return {
                english: "My newspaper is here. My book is there.",
                words: [
                    { name: "Khmer", size: "3em", tokens: "កាសែត|របស់|ខ្ញុំ|នៅ|ទីនេះ|°។ |សៀវភៅ|របស់|ខ្ញុំ|នៅ|ទីនោះ|°។" },
                    { name: "Transliteration", size: "1.8em", tokens: "kaasaet|° |rɔbɑh|° |khñom|° |nɨw|° |tii·nih|°។ |siəw·phɨw|° |rɔbɑh|° |khñom|° |nɨw|° |tii·nuh|°។" },
                    { name: "Word-by-Word", size: "2.2em", tokens: "newspaper|° |of|° |me|° |is.at|° |here|°។ |book|° |of|° |me|° |is.at|° |over.there|°។" }
                ],
                tooltipData: [
                    [base1("newspaper", "NOUN"), base1("of; belonging to", "CONJUNCTION"), base1("I; me; my", "PRONOUN"), base1("to be (at a certain location)", "VERB"), base1("here", "ADVERB"), base1("book", "NOUN"), base1("of; belonging to", "CONJUNCTION"), base1("I; me; my", "PRONOUN"), base1("to be (at a certain location)", "VERB"), base1("(over) there", "ADVERB")],
                    [khmTranslit(["kaasaet", "kaasaet", "kāsaet", "kaː.ˈsaet"]), khmTranslit(["rɔbɑh", "robah", "rps´", "rɔ.ˈɓɑh"]),
                    khmTranslit(["khñom", "k'ñom", "k̥ʰñuṃ", "kɲom"]), khmTranslit(["nɨw", "nou", "nau", "nɨw"]),
                    khmTranslit(["tii·nih", "tii·nih", "dīneḥ", "tiː ˈnih"]), khmTranslit(["siəw·phɨw", "siew·p'ou", "siavbʰau", "siəw ˈpʰɨw"]), khmTranslit(["rɔbɑh", "robah", "rps´", "rɔ.ˈɓɑh"]),
                    khmTranslit(["khñom", "k'ñom", "k̥ʰñuṃ", "kɲom"]), khmTranslit(["nɨw", "nou", "nau", "nɨw"]), khmTranslit(["tii·nuh", "tii·nuh", "dīnoḥ", "tiː ˈnuh"])],
                    []
                ]
            }
        case "korean":
            return {
                english: "April is my favorite month. I love spring.",
                words: [
                    { name: "Korean", size: "2.5em", tokens: "사|월은|° |제가|° |가장|° |좋아하는|° |달|이에요|°. |봄을|° |정말|° |좋아해요|°." },
                    { name: "Transliteration", size: "1.8em", tokens: "sa|°·|wor·ŭn|° |je·ga|° |ga·jang|° |jo·a·ha·nŭn|° |dar|°·|i·e·yo|°. |pom·ŭl|° |jŏng·mal|° |jo·a·hæ·yo|°." },
                    { name: "Word-by-Word", size: "2em", tokens: "four|° |month{TOP}|° |I{SUBJ}|° |most|° |liking|° |month|° |is|°. |spring{ACC}|° |truly|° |like|°." }
                ],
                tooltipData: [
                    [baseHanNoBase("four", "四", "NUMERAL"), baseHan("month (used when counting)", "월", "wol", "月", "NOUN"), baseHan("I; me", "저", "jŏ", "", "PRONOUN"),
                    baseHanNoBase("most; -est", "", "ADVERB"), baseHan("to like; to prefer", "좋아하다", "jo·a·ha·da", "", "VERB"), baseHanNoBase("month; the Moon", "", "Noun"),
                    baseHan("to be", "이다", "i·da", "", "ADJECTIVE"), baseHan("spring", "봄", "pom", "", "NOUN"), baseHanNoBase("really; truly", "正말", "ADVERB"), baseHan("to like; to prefer", "좋아하다", "jo·a·ha·da", "", "VERB")
                    ],
                    [korTranslit(["sa", "sa", "sa"]), korTranslit(["wor·ŭn", "wor·eun", "wŏr·ŭn"]), korTranslit(["je·ga", "je·ga", "je·ga"]),
                    korTranslit(["ga·jang", "ga·jang", "ga·jang"]), korTranslit(["jo·a·ha·nŭn", "jo·a·ha·neun", "jo·a·ha·nŭn"]), korTranslit(["dar", "dar", "dar"]),
                    korTranslit(["i·e·yo", "i·e·yo", "i·e·yo"]), korTranslit(["pom·ŭl", "pom·eul", "pom·ŭl"]), korTranslit(["jŏng·mal", "jeong·mal", "jŏng·mal"]), korTranslit(["jo·a·hæ·yo", "jo·a·hae·yo", "jo·a·hae·yo"])],
                    [null, [grammar1("Topic Marker")], [grammar1("Subject Marker")], null, null, null, null, [grammar1("Accusative (direct object) Marker")], null, null]
                ]
            }
        case "malaysian":
            return {
                english: "She reads the book to her son.",
                words: [
                    { name: "Malay", size: "2.5em", tokens: "Dia|° |membaca|° |buku|° |itu|° |kepada|° |anak|nya|°." },
                    { name: "Jawi", isRTL: true, size: "2.5em", tokens: "دي|ممباچ|° |بوکو|° |ايت|° |کڤد|° |انق‍|° |ڽ|°.\u200f" },
                    { name: "Word-by-Word", size: "1.8em", tokens: "she|° |read|° |book|° |that|° |to|° |child|° |her|°." },
                ],
                tooltipData: [
                    [base1("he; she; it", "PRONOUN"), base3("to read", "baca", "VERB"), base1("book", "NOUN"), base1("that", "DETERMINER"), base1("to (a person)", "PREPOSITION"), base1("child", "NOUN"), base3("his; her; its", "-nya", "PRONOUN")],
                    [null, null, null, null, null, null, null]
                ]
            }
        case "mandarin":
            return {
                english: "This is a man. He is eating. He is eating an apple.",
                words: [
                    { name: "Mandarin", size: "2.5em", tokens: "这|是|个|男人|°。|他|在|吃|东西|°。|他|在|吃|苹果|°。" },
                    { name: "Transliteration", size: "1.8em", tokens: "zhè|° |shì|° |gè|° |nán·rén|°。|tā|° |zài|° |chī|° |dōng·xi|°。|tā|° |zài|° |chī|° |píng·guǒ|°。" },
                    { name: "Word-by-Word", size: "2em", tokens: "this|° |is|° |{CL}|° |man|°。|he|° |{PROG}|° |eat|° |something|°。|he|° |{PROG}|° |eat|° |apple|°。" }
                ],
                tooltipData: [
                    [base1("this (one)", "PRONOUN"), base1("to be", "VERB"), base1("*general classifier for various things", "CLASSIFIER"), base1("man", "NOUN"),
                    base1("he; him", "PRONOUN"), base1("*marks an ongoing action (-ing)", "VERB"), base1("to eat", "VERB"), base1("something; stuff", "NOUN"),
                    base1("he; him", "PRONOUN"), base1("*marks an ongoing action (-ing)", "VERB"), base1("to eat", "VERB"), base1("apple", "NOUN")],
                    [
                        chnTranslit(["zhè", "ㄓㄜˋ", "chê4", "ʈʂɤ˥˩"]), chnTranslit(["shì", "ㄕˋ", "shih4", "ʂɚ˥˩"]), chnTranslit(["gè", "ㄍㄜˋ", "ko4", "kɤ˥˩"]), chnTranslit(["nán·rén", "ㄋㄢˊ ㄖㄣˊ", "nan2·jên2", "nan˧˥·ʐən˧˥"]),
                        chnTranslit(["tā", "ㄊㄚ", "t'a1", "tʰa˥˥"]), chnTranslit(["zài", "ㄗㄞˋ", "tsai4", "tsaɪ˥˩"]), chnTranslit(["chī", "ㄔ", "ch'ih1", "ʈʂʰɚ˥˥"]), chnTranslit(["dōng·xi", "ㄉㄨㄥ ㄒ", "tung1·hsi", "tʊŋ˥˥·ɕi"]),
                        chnTranslit(["tā", "ㄊㄚ", "t'a1", "tʰa˥˥"]), chnTranslit(["zài", "ㄗㄞˋ", "tsai4", "tsaɪ˥˩"]), chnTranslit(["chī", "ㄔ", "ch'ih1", "ʈʂʰɚ˥˥"]), chnTranslit(["píng·guǒ", "ㄆㄧㄥˊ ㄍㄨㄛˇ", "p'ing2·kuo3", "pʰɪŋ˧˥·kuɔ˨˩˦"])
                    ],
                    [null, null, [grammar1("Classifier / Counting Word")], null, null, [grammar1("Progressive Tense Marker")], null, null, null, [grammar1("Progressive Tense Marker")], null, null]
                ]

            }
        case "polish":
            return {
                english: "Can you open this bottle? It is difficult to open.",
                words: [
                    { name: "Polish", size: "2.5em", tokens: "Możesz|° |otworzyć|° |tę|° |butelkę|°? |Jest|° |trudna|° |do|° |otwarcia|°." },
                    { name: "Word-by-Word", size: "1.8em", tokens: "can{2SG}|° |open{INF}|° |this{F.ACC}|° |bottle{ACC}|°? |is|° |difficult{F}|° |to|° |open{VN.GEN}|°." },
                ],
                tooltipData: [
                    [base3("to be able to; can", "móc", "VERB"), base1("to open", "VERB"), base3("this (feminine)", "ta", "DETERMINER"), base3("bottle", "butelka", "NOUN"), base3("to be", "być", "VERB"), base3("difficult; hard", "trudny", "ADJECTIVE"), base1("to; towards", "PREPOSITION"), base3("to open", "otworzyć", "VERB")],
                    [[grammar1("Second-Person Singular ('you')")], [grammar1("Infinitive / Dictionary Form")], [grammar1("Feminine Form"), grammar1("Accusative (direct object) Case")], [grammar1("Accusative (direct object) Case")], null, [grammar1("Feminine Form")], null, [grammar1("Verbal Noun"), grammar1("Genitive Case")]]
                ]
            }
        case "bra_portuguese":
            return {
                english: "My friend gave a present to her mother.",
                words: [
                    { name: "Portuguese", size: "2.5em", tokens: "Minha|° |amiga|° |deu|° |um|° |presente|° |para|° |a|° |mãe|° |dela|°." },
                    { name: "Word-by-Word", size: "1.8em", tokens: "my{F}|° |friend{F}|° |gave{3SG}|° |a{M}|° |present|° |towards|° |the{F}|° |mother|° |of.her|°." },
                ], tooltipData: [
                    [base3("my", "meu / minha", "PRONOUN"), base3("friend (feminine)", "amigo / amiga", "NOUN"), base3("to give", "dar", "VERB"), base3("a; an", "um / uma", "ARTICLE"), base1("present; gift", "NOUN"), base1("for; to", "PREPOSITION"), base3("the (feminine)", "o / a", "ARTICLE"), base1("mother", "NOUN"), base3("(of) her", "dele / dela", "PREPOSITION")],
                    [[grammar1("Feminine Form")], [grammar1("Feminine Form")], [grammar1("Third-Person Singular ('he/she/it')")], [grammar1("Masculine Form")], null, null, [grammar1("Feminine Form")], null, null]
                ]
            }
        case "eur_portuguese":
            return {
                english: "Nice to meet you. What is your name?",
                words: [
                    { name: "Portuguese", size: "2.5em", tokens: "Prazer|° |em|° |conhecer|°-|te|°. |Como|° |te|° |chamas|°?" },
                    { name: "Word-by-Word", size: "1.8em", tokens: "pleasure|° |in|° |knowing{INF}|°-|you{ACC}|°. |how|° |yourself|° |call{2SG}|°?" },
                ], tooltipData: [
                    [base1("pleasure", "NOUN"), base1("in; on", "PREPOSITION"), base1("to meet; to know", "VERB"), base3("you (informal)", "tu", "PRONOUN"), base1("how", "ADVERB"), base3("you (informal)", "tu", "PRONOUN"), base3("to call (a name)", "chamar", "VERB")],
                    [null, null, [grammar1("Infinitive / Dictionary Form")], [grammar1("Accusative (direct object) Case")], null, null, [grammar1("Second-Person Singular ('you')")]]
                ]
            }
        case "russian":
            return {
                english: "I'm an English teacher. I teach English.",
                words: [
                    { name: "Russian", size: "2.5em", tokens: "Я|° |учитель|° |английского|°. |Я|° |преподаю|° |английский|°." },
                    { name: "Transliteration", size: "1.8em", tokens: "ya|° |uchitel'|° |angliyskogo|°. |ya|° |prepodayu|° |angliyskiy|°." },
                    { name: "Word-by-Word", size: "2em", tokens: "I|° |teacher{M}|° |English{GEN}|°. |I|° |teach{1SG}|° |English{ACC}|°." }
                ],

                tooltipData: [
                    [base1("I", "PRONOUN"), base1("teacher", "NOUN"), base3("English (language)", "английский", "NOUN"), base1("I", "PRONOUN"), base3("to teach", "преподавать", "VERB"), base3("English (language)", "английский", "NOUN")],
                    [rusTranslit("ya", "ja"), rusTranslit("uchitel'", "učitel'"), rusTranslit("angliyskogo", "anglijskogo"), rusTranslit("ya", "ja"), rusTranslit("prepodayu", "prepodaju"), rusTranslit("angliyskiy", "anglijskij")],
                    [null, [grammar1("Masculine Form")], [grammar1("Genitive Case ('of')")], null, [grammar1("First-Person Singular ('I')")], [grammar1("Accusative (direct object) Case")]]
                ]
            }
        case "eur_spanish":
            return {
                english: "Are tomatoes vegetables? - I think they are fruit.",
                words: [
                    { name: "Spanish", size: "2.5em", tokens: "^¿|Los|° |tomates|° |son|° |verduras|°? |^- |Creo|° |que|° |son|° |frutas|°." },
                    { name: "Word-by-Word", size: "1.8em", tokens: "^¿|the{M.PL}|° |tomatoes|° |are{3PL}|° |vegetables|°? |^- |believe{1SG}|° |that|° |are{3PL}|° |fruits|°." },
                ], tooltipData: [
                    [base3("the (plural)", "los / las", "ARTICLE"), base3("tomato", "tomate", "NOUN"), base3("to be", "ser", "VERB"), base3("vegetable", "verdura", "NOUN"), base3("to believe", "creer", "VERB"), base1("that", "CONJUNCTION"), base3("to be", "ser", "VERB"), base3("fruit", "fruta", "NOUN")],
                    [[grammar1("Masculine Form"), grammar1("Plural Form")], null, [grammar1("Third-Person Plural ('they')")], null, [grammar1("First-Person Singular ('I')")], null, [grammar1("Third-Person Plural ('they')")], null]
                ]
            }
        case "mex_spanish":
            return {
                english: "What is that thing behind you? - It's a potato.",
                words: [
                    { name: "Spanish", size: "2.5em", tokens: "^¿|Qué|° |es|° |eso|° |que|° |tienes|° |detrás|°? |^- |Es|° |una|° |papa|°." },
                    { name: "Word-by-Word", size: "1.8em", tokens: "^¿|what|° |is|° |that{N}|° |that|° |have{2SG}|° |behind|°? |^- |is|° |a{F}|° |potato|°." },
                ], tooltipData: [
                    [base1("what; which", "PRONOUN"), base3("to be", "ser", "VERB"), base3("that (thing)", "ese", "PRONOUN"), base1("that; which; who", "CONJUNCTION"), base3("to have", "tener", "VERB"), base1("behind; after", "ADVERB"), base3("to be", "ser", "VERB"), base3("a; an", "un / una", "ARTICLE"), base1("potato", "NOUN")],
                    [null, null, [grammar1("Neuter Form")], null, [grammar1("Second-Person Singular ('you')")], null, null, [grammar1("Feminine Form")], null]
                ]
            }
        case "thai":
            return {
                english: "I have a dog and a cat. They are friends.",
                words: [
                    { name: "Thai", size: "2.5em", tokens: "ผม|มี|หมา|กับ|แมว|° |พวก|มัน|เป็น|เพื่อน|กัน" },
                    { name: "Transliteration", size: "1.8em", tokens: "pǒm|° |mii|° |mǎa|° |gàp|° |mɛɛo|°. |pûak|° |man|° |bpen|° |pʉ̂an|° |gan" },
                    { name: "Word-by-Word", size: "2em", tokens: "I{M}|° |have|° |dog|° |and|° |cat|°. |{PL}|° |it|° |are|° |friend|° |each.other" }
                ], tooltipData: [
                    [base1("I (male)", "PRONOUN"), base1("to have", "VERB"), base1("dog", "NOUN"), base1("and", "CONJUNCTION"), base1("cat", "NOUN"), base1("*plural marker before a pronoun", "PARTICLE"), base1("it", "PRONOUN"), base1("to be", "VERB"), base1("friend", "NOUN"), base1("each other; together", "PRONOUN")],
                    [thaTranslit("pǒm", "phǒm", "pʰom˩˦"), thaTranslit("mii", "mi", "miː˧"), thaTranslit("mǎa", "mǎ", "maː˩˦"),
                    thaTranslit("gàp", "kàp", "kap˨˩"), thaTranslit("mɛɛo", "me̩o", "mɛːo˧"), thaTranslit("pûak", "phûak", "pʰuak˥˩"),
                    thaTranslit("man", "man", "man˧"), thaTranslit("bpen", "pen", "pen˧"), thaTranslit("pʉ̂an", "phư̂an", "pʰɯan˥˩"), thaTranslit("gan", "kan", "kan˧")],
                    [[grammar1("Male Form")], null, null, null, null, [grammar1("Plural Marker")], null, null, null, null]
                ]
            }
        case "turkish":
            return {
                english: "I drive a very fast, very expensive car.",
                words: [
                    { name: "Turkish", size: "2.5em", tokens: "Ben|° |çok|° |hızlı|° |ve|° |çok|° |pahalı|° |bir|° |araba|° |kullanıyorum|°." },
                    { name: "Word-by-Word", size: "1.8em", tokens: "I|° |very|° |fast|° |and|° |very|° |expensive|° |a|° |car|° |am.driving|°." },
                ], tooltipData: [
                    [base1("I", "PRONOUN"), base1("very; much", "ADVERB"), base1("fast; quick", "ADJECTIVE"), base1("and", "CONJUNCTION"), base1("very; much", "ADVERB"), base1("expensive", "ADJECTIVE"),
                    base1("a; an; one", "NUMERAL"), base1("car", "NOUN"), base3("to use; to drive", "kullanmak", "VERB")],
                    []
                ]
            }
        case "ukrainian":
            return {
                english: "I want to bake a cake, but I don't have any eggs.",
                words: [
                    { name: "Ukrainian", size: "2.5em", tokens: "Я|° |хочу|° |приготувати|° |торт|°, |але|° |у|° |мене|° |немає|° |яєць|°." },
                    { name: "Transliteration", size: "1.8em", tokens: "ya|° |khochu|° |pryhotuvaty|° |tort|°, |ale|° |u|° |mene|° |nemaye|° |yayetsʹ|°." },
                    { name: "Word-by-Word", size: "2em", tokens: "I|° |want{1SG}|° |prepare{PERF.INF}|° |cake|°, |but|° |by|° |me{GEN}|° |there.isn't|° |eggs{GEN}|°." }
                ], tooltipData: [
                    [base1("I", "PRONOUN"), base3("to want", "хотіти", "VERB"), base3("to prepare; to cook", "приготувати", "VERB"), base1("cake", "NOUN"), base1("but; however", "CONJUNCTION"), base1("by (indicates possession)", "PREPOSITION"),
                    base3("I", "я", "PRONOUN"), base1("there isn't", "VERB"), base3("egg", "яйце", "NOUN")],
                    [ukrTranslit("ya", "ya"), ukrTranslit("khochu", "khochu"), ukrTranslit("pryhotuvaty", "pryhotuvaty"),
                    ukrTranslit("tort", "tort"), ukrTranslit("ale", "ale"), ukrTranslit("u", "u"),
                    ukrTranslit("mene", "mene"), ukrTranslit("nemaye", "nemaie"), ukrTranslit("yayetsʹ", "yayets")],
                    [null, [grammar1("First-Person Singular ('I')")], [grammar1("Perfective Verb"), grammar1("Infinitive / Dictionary Form")], null, null, null, [grammar1("Genitive Case ('of')")], null, [grammar1("Genitive Case ('of')")]]
                ]
            }
        case "vietnamese":
            return {
                english: "Do you work here? - Yes, how may I help you?",
                words: [
                    { name: "Vietnamese", size: "2.5em", tokens: "Em|° |làm|° |việc|° |ở|° |đây|° |à|°? |^- |Vâng|°, |em|° |có thể|° |giúp|° |gì|° |không|°?" },
                    { name: "Word-by-Word", size: "1.8em", tokens: "younger.sibling|° |do|° |work|° |at|° |here|° |{Q}|°? |^- |yes|°, |younger.sibling|° |can|° |help|° |what|° |{Q}|°?" },
                ], tooltipData: [
                    [baseHanNoBase("younger sibling; you (informal)", "㛪", "PRONOUN", true), baseHanNoBase("to do; to work", "爫", "VERB", true), baseHanNoBase("work; job", "役", "NOUN", true), baseHanNoBase("at; in; on", "於", "PREPOSITION", true),
                    baseHanNoBase("here", "低", "PRONOUN", true), baseHanNoBase("*invites the listener to confirm a question", "啊", "PARTICLE", true),
                    baseHanNoBase("yes", "𠳐", "EXPRESSION", true), baseHanNoBase("younger sibling; you (informal)", "㛪", "PRONOUN", true), baseHanNoBase("to be able to; can", "固体", "VERB", true), baseHanNoBase("to help; to do sth. for sb.", "𠢞", "VERB", true),
                    baseHanNoBase("what; which; anything", "之", "PRONOUN", true), baseHanNoBase("no; not; ...or not?", "空", "ADVERB", true)],
                    [null, null, null, null, null, [grammar1("Question Particle")], null, null, null, null, null, [grammar1("Question Particle")]]
                ]
            }
        case "sth_vietnamese":
            return {
                english: "I want to go into this shop. It's a shoe shop.",
                words: [
                    { name: "Vietnamese", size: "2.5em", tokens: "Tôi|° |muốn|° |vô|° |cửa hàng|° |này|°. |Đó|° |là|° |một|° |cửa hàng|° |bán|° |giày|°." },
                    { name: "Word-by-Word", size: "1.8em", tokens: "I|° |want|° |enter|° |store|° |this|°. |that|° |is|° |a|° |store|° |sell|° |shoe|°." },
                ], tooltipData: [
                    [baseHanNoBase("I (formal)", "碎", "PRONOUN", true), baseHanNoBase("to want", "㦖", "VERB", true), baseHanNoBase("to enter; to go into", "無", "VERB", true),
                    baseHanNoBase("store", "𨷶行", "NOUN", true), baseHanNoBase("this", "尼", "DETERMINER", true),
                    baseHanNoBase("that (one)", "妬", "PRONOUN", true), baseHanNoBase("to be", "𪜀", "VERB", true), baseHanNoBase("a; an; one", "𠬠", "NUMERAL", true),
                    baseHanNoBase("store", "𨷶行", "NOUN", true), baseHanNoBase("to sell", "半", "VERB", true), baseHanNoBase("shoe", "𠫅", "NOUN", true)],
                    []
                ]
            }
    }
}

export const getColor = (langCode, index) => {
    let base = {
        "std_arabic": "#005430",
        "cantonese": "#555555",
        "catalan": "#BC0007",
        "dutch": "#1E4785",
        "filipino": "#007A3D",
        "french": "#0055A4",
        "german": "#CD0004",
        "hindi": "#FF671F",
        "indonesian": "#CD0004",
        "japanese": "#BC002D",
        "khmer": "#032ea1",
        "korean": "#263D5E",
        "malaysian": "#A7282D",
        "mandarin": "#756621",
        "polish": "#555555",
        "bra_portuguese": "#078A2E",
        "eur_portuguese": "#006600",
        "russian": "#0036A7",
        "eur_spanish": "#756621",
        "mex_spanish": "#0F6A49",
        "thai": "#782A3D",
        "turkish": "#CD0004",
        "ukrainian": "#DDAA22",
        "vietnamese": "#756621",
        "sth_vietnamese": "#CA030B",
    }[langCode]
    return colorMixer(base, "#ffffff");
}

