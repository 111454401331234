import React, { Component, useEffect, useState } from "react";
import Fade from "react-reveal";
import image from "./images/icon_big.png"
import { createTokens, getColor, getData } from "./LanguageData";
import { LanguageDropdown } from "./LanguageDropdown";
import { playAudio, playSentenceAudio } from "./utils";
import { Tooltip } from 'react-tooltip'
import ParticlesBg from "particles-bg";
import useWindowDimensions from "./windowDimensions";

const Languages = ({ language, setLanguage }) => {
  const { height, width } = useWindowDimensions();
  const [languageData, setLanguageData] = useState({});
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [selectedToken, setSelectedToken] = useState(0);
  const [selectedGrammarToken, setSelectedGrammarToken] = useState(0);

  useEffect(() => {
    if (language == null) return;
    var data = getData(language.value);
    setLanguageData(data);
    setRows(data.words);
  }, [language]);

  const sendMouseEnter = (row, token) => {
    for (var x = 0; x < rows.length; x++) {
      if (x == row) continue;
      var element = document.getElementById(x + "." + token);
      if (element) element.classList.add("passive-hover");
    }
  }

  const sendMouseLeave = (row, token) => {
    for (var x = 0; x < rows.length; x++) {
      if (x == row) continue;
      var element = document.getElementById(x + "." + token);
      element.classList.remove("passive-hover");
    }
  }

  const getTooltipLocation = (rowIndex) => {
    if (rowIndex == 0) return "top";
    if (rowIndex == rows.length - 1) return "bottom";
    return "right";
  }

  const getTooltipData = () => {
    try {
      let result = languageData.tooltipData[selectedRow][selectedToken];
      if (Array.isArray(result)) {
        return result[selectedGrammarToken];
      }
      return result;
    } catch (exception) {
      return null
    }
  }

  const playSentence = () => {
    playSentenceAudio(language.value, (index) => {
      sendMouseEnter(-1, index);
      if (index > 0) sendMouseLeave(-1, index - 1);
    });
  }

  return <section id="languages" style={{ height: height }}>
    <ParticlesBg type="polygon" bg={{
      position: "absolute",
      zIndex: 0,
      width: "100%"
    }} />

    <Fade duration={1000}>
      <center>
        <div className="dropdown-container">
          <LanguageDropdown value={language} setValue={setLanguage} />
        </div>
      </center>
      <div style={{ flexGrow: 1 }} />
      {language == null ? <></> :
        <div className="main-container">
          <div id="title-row" onClick={() => playSentence()}>
            <span className="english-title">
              {"»" + languageData.english + "«"}
            </span>
            <img style={{ height: 25, marginLeft: 10, marginTop: 22 }} src={require("./images/play_icon.png")} />
          </div>
          {rows.map((row, rowIndex) => {
            var title = row.name;
            var baseTokens = createTokens(rows[0].tokens);
            var tokens = createTokens(row.tokens);
            var isRTL = row.isRTL === true;

            return <div key={rowIndex}>{rowIndex > 0 ? <h3 className="row-title">{title}</h3> : <></>}
              <div className="token-row" style={{ flexDirection: isRTL ? "row-reverse" : "row" }} >
                {tokens.map((token, tokenIndex) => {
                  return <div key={tokenIndex}
                    data-tooltip-id={title === "Word-by-Word" && !token.text.startsWith("{") ? "" : "tooltip"}
                    data-tooltip-place={getTooltipLocation(rowIndex)}
                    onMouseEnter={() => { setSelectedRow(rowIndex); setSelectedToken(tokenIndex); }}
                  >
                    <div style={{ fontSize: row.size, position: "relative" }}>
                      <span className="token-text">{token.before}</span>
                      <span style={{ color: getColor(language.value, rowIndex) }}>
                        <span
                          className={"token-text token-main" + (token.text.startsWith("{") ? " lower-tag" : "")}
                          id={rowIndex + "." + tokenIndex}
                          onMouseEnter={() => sendMouseEnter(rowIndex, tokenIndex)}
                          onMouseLeave={() => sendMouseLeave(rowIndex, tokenIndex)}
                          onClick={() => {
                            playAudio(language.value, baseTokens[tokenIndex].text);
                          }}>
                          {token.text}
                        </span>
                      </span>
                      {token.tags !== undefined ?
                        token.tags.map((token, index) =>
                          <>
                            <span className="wbw-token">{(index > 0 ? "." : "") + token}</span>
                            <span className="wbw-token dummy"
                              data-tooltip-id="tooltip"
                              data-tooltip-place="bottom"
                              onMouseEnter={() => {
                                setSelectedRow(rowIndex);
                                setSelectedToken(tokenIndex);
                                setSelectedGrammarToken(index);
                                sendMouseEnter(rowIndex, tokenIndex);
                              }}
                              onMouseLeave={() => sendMouseLeave(rowIndex, tokenIndex)}
                            >{(index > 0 ? "." : "") + token}</span>
                          </>
                        )
                        : <></>}

                      <span className="token-text">{token.after}</span>
                    </div>
                  </div>
                })}</div>
            </div>
          })}
        </div>}
      <div style={{ flexGrow: 2 }} />

    </Fade>

    <Tooltip className="tooltip"
      classNameArrow="tooltip-arrow"
      style={{ display: getTooltipData() === null ? "none" : "block" }}
      id={"tooltip"}>
      <div style={{ width: "max-content", zIndex: 999 }}>
        {languageData.tooltipData == undefined ? <></> : getTooltipData()}
      </div>
    </Tooltip>
  </section >
}

export default Languages;
