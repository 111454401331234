import React, { Component } from "react";
import Fade from "react-reveal";

const Footer = () => {
  return <footer>
    <div className="row">
      <Fade bottom>
        <div className="twelve columns">
          <ul className="copyright">
            <li>&copy; Copyright {new Date().getFullYear()} Alexander Stebner</li>
          </ul>
          <ul className="copyright">
            <li><a href="https://lingora.org/privacy">Privacy Policy</a></li>
            <li><a href="https://lingora.org/tos">Terms of Service</a></li>
          </ul>
        </div>
      </Fade>

      <div id="go-top">
        <a className="smoothscroll" title="Back to Top" href="#home">
          <i className="icon-up-open"></i>
        </a>
      </div>
    </div>
  </footer>
}

export default Footer;
