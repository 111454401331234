const Tos = () => {
    return <div style={{ width: "80%", marginLeft: 50, marginRight: 50 }}>
        <h1><strong>Terms of Service</strong></h1>

        <h1>I.&nbsp;Acceptance of Terms</h1>

        <p>1.1 Lingora (“we, “us”, or “our”) provides language learning services (the “Services”) through its website (<a href="https://lingora.org">https://lingora.org</a>) and mobile applications. Lingora is privately owned by Alexander Stebner, resident of Alstertwiete 15, 20099, Hamburg, Germany.</p>

        <p>1.2 The Services are professionally produced and do not contain third party advertising. Access and use of the Services is subject to these Terms of Service (“Terms”). Unless expressly stated otherwise, any new features added to the existing Services, including any new tools or content, are also provided to you subject to these Terms.</p>

        <p>1.3 By accessing or using any part of the Services, you represent that:</p>

        <p>a)&nbsp;you have read, understood, and agree to be bound by these Terms;</p>

        <p>b)&nbsp;you are at least 13 years old, and if you are younger than 18 years of age, you have the permission of your parent or legal guardian, who will take responsibility for your compliance with these Terms; and</p>

        <p>c)&nbsp;you are not</p>

        <p>i)&nbsp;organized under the laws of, operating from, or resident in, a territory or country embargoed by Germany or the United States;</p>

        <p>ii)&nbsp;identified on a list of prohibited or restricted parties; or</p>

        <p>iii)&nbsp;in any other way blocked, denied or subject to sanctions by the government of Germany or the United States, or by similar laws applicable in other jurisdictions.</p>

        <p>1.4 Lingora may update these Terms from time to time. You can review the most current version of the Terms at <a href="https://lingora.org/tos">https://lingora.org/tos</a>. Your continued use of the Services after any such modifications constitutes your acceptance of the new Terms.</p>

        <p>1.5 If you do not agree to abide by these or any future Terms, do not use or access (or continue to use or access) the Services.</p>

        <h2>II.&nbsp;Free and Paid Services</h2>

        <p>2.1 Lingora provides both free and paid versions of the Services (“Free Services” and “Paid Services”, respectively). The Free Services provide you with limited or conditional access to learning functionalities and content. You have the option of subscribing to Paid Services for different subscription periods and under different tiers of membership to access additional features and learning material.</p>

        <p>2.2 Membership subscriptions can be made via in-app purchases at the then-current prices.</p>

        <p>2.3 When you purchase a periodic subscription for Paid Services, your subscription will automatically renew unless you cancel it. At the end of your current subscription period, your chosen payment method will be charged automatically (at the price in effect at your time of purchase), and your subscription will be renewed for the same period of time.</p>

        <p>2.4 Lingora uses secure payment service providers to ensure that your payments are secure. All financial transactions in connection with the Services will be processed in accordance with the respective payment service provider’s terms of use, privacy policy, and/or any applicable payment terms and conditions. Lingora will not be responsible for the actions or inaction of third party payment service providers.</p>

        <p>2.5 Where we add new content, features and functions to the Services:</p>

        <p>a)&nbsp;your existing membership will entitle you to access all Paid Services available to your membership tier at the time of purchase (“Existing Services”), including any updates and language courses added to those Existing Services, for as long as these Paid Services are offered and supported by Lingora; and</p>

        <p>b)&nbsp;in certain cases, you may be required to make additional purchases to access content, features and functions outside the Existing Services, including but not limited to content for which we require a third-party license.</p>

        <p>2.6 From time to time, Lingora may run promotions and free offers which may be subject to additional terms or restrictions.</p>

        <p>2.7 Lingora may revise the pricing of the Services, and any goods and services offered through the Services at any time. Price changes will not affect existing subscriptions. (So if you signed up for a membership for a certain price, you won’t be affected by any price changes unless we notify you otherwise. You will be notified by email of any price changes that affect you before they are applied.).</p>

        <p>2.8 Lingora may, at any time in accordance with applicable law, modify, suspend, or discontinue the Services (or any part thereof). This may include (but is not limited to):</p>

        <p>a)&nbsp;upgrading, downgrading, expanding, limiting or otherwise modifying the scope of the Free or Paid Services;</p>

        <p>b)&nbsp;changes to maintain compliance with applicable law; and</p>

        <p>c)&nbsp;changes that affect the design, operation, specifications, content, features and other functions of the Services.</p>

        <p>In making any such modifications, we will take into account your and Lingora’s legitimate interests. You agree that Lingora shall not be liable to you or to any third party for any modification or discontinuance of the Services, or any part thereof.</p>

        <p>2.9 Lingora shall use reasonable efforts consistent with prevailing industry standards to provide the Services in a manner which minimizes errors and interruptions. We shall use commercially reasonable efforts to ensure timely correction of any errors notified to us, subject to</p>

        <p>a)&nbsp;you providing a detailed description of the error and its reproducibility to Lingora; and</p>

        <p>b)&nbsp;the priority of the error, as determined by Lingora in its sole discretion.</p>

        <p>You may notify Lingora of any errors via&nbsp;<a href="mailto:stebalex@gmail.com">stebalex@gmail.com</a>,&nbsp;the website's “Contact Us” function, or any other contact channel we may provide from time to time.</p>

        <h2>III.&nbsp;License and Registration</h2>

        <p>3.1 Subject to these Terms, Lingora grants you a non-exclusive, non-transferable, non-sublicensable, world-wide right and license to use the Services for personal and non-commercial purposes (“License”).</p>

        <p>3.2 Your license to use the Services is restricted to use (including download and installation of any Lingora mobile apps) in object code form only, and you agree that you will not access the Services except through interfaces provided by Lingora.</p>

        <p>3.3 It is a condition of the license under clause 3.1 above that, except with the express prior written consent of Lingora, you must not and must not permit any third party to:</p>

        <p>a)&nbsp;decompile, reverse engineer or disassemble any portion of the Services, or otherwise attempt to discover any source code of the Services;</p>

        <p>b)&nbsp;sublicense, sell, resell, rent, assign or transfer any rights pertaining to the Services, or otherwise make any business or commercial use of the Services;</p>

        <p>c)&nbsp;copy, modify, distribute, publicly reproduce or create a derivative work from or based on the Services, in whole or in part; or</p>

        <p>d)&nbsp;engage in or use any data mining, robots, scraping or similar data gathering or extraction methods in connection with the Services.</p>

        <p>3.4 You acknowledge and agree that Lingora and its licensors own and shall retain all intellectual property and other rights in and to the Services (and any changes, modifications, or corrections thereto), including the Lingora website and mobile applications, any necessary software used in connection with the Services, and all content (including text, images, videos, audio or other materials) contained therein.</p>

        <h2>IV.&nbsp;Privacy</h2>

        <p>4.1 In order to provide you with the Services, we collect usage data about you. The collection, use and disclosure of this information is governed by our Privacy Policy, which is incorporated into these Terms by reference. You can access our privacy policy at <a href="https://lingora.org/privacy">https://lingora.org/privacy</a>&nbsp;</p>

        <p>By using or continuing to use the Services, you also indicate that you understand and consent to the terms of our Privacy Policy.</p>

        <p>4.2 If you are an administrator of a third party facilitator (for example, a school or an employer), you acknowledge that you will have access to Registration Data and account usage details for the accounts you administer. You agree to treat such data in accordance with our Privacy Policy, in particular to keep the data confidential and use it only for the purpose of administering and monitoring the usage of Paid Services under the administered accounts.</p>

        <h2>V.&nbsp;User Obligations</h2>

        <p>5.1 You agree to use the Services in a lawful and appropriate manner, and to ensure you do not violate any applicable local, state, national or international law or any rights of third parties. In particular, you agree not to use the Services in a manner that:</p>

        <p>a)&nbsp;violates any laws, rules or regulations regarding the transmission of data or information exported from Germany, the United States and/or the jurisdiction in which you reside;</p>

        <p>b)&nbsp;is threatening, abusive, harassing, defamatory, libelous, hateful, vulgar, obscene or offensive, or is otherwise objectionable, violates any law, or infringes on the right of any third party (including intellectual property, privacy, reputation and any other personal or proprietary rights);</p>

        <p>c)&nbsp;is, or may be, fraudulent or misleading, for example, forging headers or otherwise manipulating any content transmitted through the Services to disguise its origin;</p>

        <p>d)&nbsp;interferes with or disrupts the Services, or servers or networks connected to the Services, or which otherwise restricts or inhibits any other person from using the Services; or</p>

        <p>e)&nbsp;may expose Lingora or its users to any harm or liability of any kind, for example, posting, transmitting or otherwise making available any unsolicited or unauthorized promotional materials or "spam", or any viruses, codes, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment.</p>

        <p>5.2 In connection with registering for and using the Services, you agree to:</p>

        <p>a)&nbsp;provide true, accurate, up-to-date and complete information about yourself (and/or your organization) as prompted during the registration process;</p>

        <p>b)&nbsp;maintain and update the Registration Data to ensure that it remains true, accurate, up to date and complete;</p>

        <p>c)&nbsp;maintain the confidentiality of your password and other information related to the security of your account, and to protect your account from unauthorized or fraudulent use;</p>

        <p>d) be fully responsible for all use of your account and for any actions that take place through your account, including the payment of all fees and applicable taxes incurred by anyone using a Lingora account registered to you; and</p>

        <p>e)&nbsp;pay all charges (including any applicable taxes) incurred by users of your credit card, debit card, or other payment method used in connection with purchases and other monetary transaction interactions with the Services (e.g. purchases made via an account registered to a minor).</p>

        <h2>VI.&nbsp;User Content</h2>

        <p>6.1 You must ensure that all data and any other material uploaded, displayed, posted, stored, exchanged or transmitted by you on or through the Services (“User Content”) complies with clause 5.1 above. You shall be solely responsible for all User Content you generate.</p>

        <p>6.2 You shall retain all right, title and interest in and to your User Content. You grant Lingora a royalty-free, perpetual, worldwide, non-exclusive, sublicensable and transferable license to use your User Content (with or without acknowledgement to you) in connection with promoting, providing and improving the Services, or as otherwise required by law. You represent that you own or have secured all legal rights necessary to grant such a license.</p>

        <p>6.3 You understand that other users may have access to your User Content, and that neither they or Lingora have any obligation to you or anyone else to maintain its confidentiality.</p>

        <p>6.4 Lingora does not monitor User Content. However, Lingora shall have the right (but not the obligation) to refuse, remove or delete any User Content that breaches these Terms or is otherwise (in our sole discretion) objectionable.</p>

        <p>6.5 Lingora shall not be liable in any way for any User Content, including, but not limited to, any errors or omissions in any User Content, or any loss or damage of any kind incurred as a result of the use of any User Content made available via the Services.</p>

        <p>6.6 Without limiting clauses 6.4 and 6.7, Lingora shall use commercially reasonable efforts to ensure that your User Content, including your learning progress and any personalized settings in your account, are accurately stored, maintained, transmitted and/or displayed on the Services.</p>

        <p>6.7 You acknowledge that Lingora may establish general practices and limits concerning storage of data on the Services and may modify such practices and limits from time to time without notice to you. You agree that Lingora has no responsibility or liability for the loss, deletion or failure to store any User Content uploaded on or through the Services. You further acknowledge that Lingora reserves the right to terminate and delete accounts that are inactive for extended periods of time.</p>

        <h2>VII.&nbsp;Third Party Content and Links to Third Party Websites</h2>

        <p>7.1 The Services may contain third-party content and links to third party websites or services that are not owned or controlled by Lingora. We do not endorse or assume any responsibility for any such third party sites, or any information, materials, products or services that may be included or advertised therein. You expressly acknowledge and agree that Lingora shall not be responsible or liable, directly or indirectly, for any damage or loss arising from your use of any third-party website, service or content, or from your dealings with any operators of such sites, services or content.</p>

        <p>7.2 If you access any third party website, service, or content via the Services, you understand that the third party operator’s terms of service, and not Lingora’s Terms and Privacy Policy, will apply to your use of such sites, services or content.</p>

        <h2>VIII.&nbsp;Termination and Refunds</h2>

        <p>8.1 You may cancel your subscription to Paid Services at any time through your Apple ID or Google Account. Your subscription will terminate at the end of your then-current subscription period, and you will continue to have access to the relevant Paid Services until the time of termination. If you do not wish your subscription to be automatically renewed, you will need to cancel at least 24 hours before your payment is due to be deducted. (You can check the length of your subscription, as well as the expiration and next payment dates through your Apple ID or Google Account.)</p>

        <p>8.2 If you are using Paid Services through a subscription facilitated or paid for by a third party, that third party facilitator may also terminate your subscription. For example, if your subscription was facilitated/paid for by your school or employer, they may terminate your subscription if your enrollment or employment ceases. If your third party facilitator terminates your subscription, you will still have access to your account and to Free Services, and you may subscribe to Lingora individually.</p>

        <p>8.3 All payments are non-refundable. There are no refunds or credits for partially used membership periods. However, refunds or credits may be granted on a case-by-case basis at Lingora’s sole discretion. The grant of a refund or credit in one instance does not obligate Lingora to provide a refund or credit in the future, under any circumstances.</p>

        <p>8.4 You agree that the Services constitute digital content that is immediately available to you upon purchase/subscription. You acknowledge that, as a result, you waive any statutory right of withdrawal from contracts for purchases of digital content.</p>

        <p>8.5 Lingora may terminate your access to and use of the Services if we have reason to believe that you have breached or acted inconsistently with these Terms. Following such termination, Lingora may also delete any or all of your User Content from our servers.</p>

        <h2>IX.&nbsp;INDEMNITY AND LIMITATION OF WARRANTIES AND LIABILITY</h2>

        <p>9.1 YOU ACKNOWLEDGE AND AGREE THAT THE SERVICES ARE PROVIDED ON AN "AS IS" BASIS, AND THAT YOU USE THE SERVICES AT YOUR OWN RISK. LINGORA DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND MAKES NO REPRESENTATION OR WARRANTY IN RELATION TO THE ACCURACY, COMPLETENESS, INFORMATIONAL CONTENT OR NON-INFRINGEMENT OF THE SERVICES.</p>

        <p>9.2 LINGORA DOES NOT GUARANTEE, REPRESENT OR WARRANT ANY SPECIFIC LANGUAGE LEARNING PROGRESS OR LANGUAGE LEARNING RESULTS TO BE OBTAINED THROUGH USE OF THE SERVICES.</p>

        <p>9.3 LINGORA EXPRESSLY DISCLAIMS, TO THE FULLEST EXTENT PERMITTED BY LAW, ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>

        <p>9.4 TO THE FULLEST EXTENT PERMITTED BY LAW, LINGORA SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATING TO THE ACCESS OR USE OF, OR THE INABILITY TO ACCESS OR USE, THE SERVICES OR ANY PORTION THEREOF (EVEN IF LINGORA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), INCLUDING BUT NOT LIMITED TO:</p>

        <p>a)&nbsp;LOSS OF USE OF THE SERVICE;</p>

        <p>b)&nbsp;INACCURATE RESULTS;</p>

        <p>c)&nbsp;LOSS OF PROFITS, OPPORTUNITY OR ANTICIPATED SAVINGS;</p>

        <p>d)&nbsp;BUSINESS INTERRUPTION;</p>

        <p>e)&nbsp;LOSS OF GOODWILL; OR</p>

        <p>f)&nbsp;LOSS OR CORRUPTION OF DATA.</p>

        <p>9.5 THE MAXIMUM AGGREGATE LIABILITY OF LINGORA TO YOU IN RELATION TO THE SERVICES (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), BREACH OF STATUTORY DUTY OR OTHERWISE) SHALL, TO THE FULLEST EXTENT PERMITTED BY LAW, NOT EXCEED THE AMOUNT PAID BY YOU FOR THE SERVICES, OR ONE HUNDRED DOLLARS ($100), WHICHEVER IS THE GREATER.</p>

        <p>9.6 YOU AGREE THAT, NOTWITHSTANDING ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICES, OR TO THESE TERMS, MUST BE FILED WITHIN ONE YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION AROSE OR BE FOREVER BARRED.</p>

        <p>9.7 YOU AGREE TO INDEMNIFY AND HOLD HARMLESS LINGORA AND ITS AFFILIATES, AND ITS AND THEIR DIRECTORS, OFFICERS, EMPLOYEES, CONTRACTORS, AGENTS, SUPPLIERS, LICENSORS, SUCCESSORS AND ASSIGNS, FROM AND AGAINST ANY LOSS, DAMAGES, COST, EXPENSE (INCLUDING LEGAL FEES) OR OTHER LIABILITY ARISING OUT OF ANY CLAIM, DEMAND, ALLEGATION, PROCEEDING OR OTHER CAUSE OF ACTION BROUGHT BY ANY THIRD PARTY DUE TO OR ARISING FROM YOUR ACCESS OR USE OF THE SERVICES, YOUR USER CONTENT, YOUR BREACH OF THESE TERMS, YOUR VIOLATION OF ANY RIGHTS OF ANOTHER PERSON OR ENTITY, OR FROM ANY FALSE OR MISLEADING REPRESENTATION ON YOUR PART.</p>

        <p>9.8 IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE SECTION 1542 OF THE CALIFORNIA CIVIL CODE, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.” IF YOU ARE A RESIDENT OF ANOTHER JURISDICTION, YOU WAIVE ANY COMPARABLE STATUTE OR DOCTRINE.</p>

        <h2>X.&nbsp;Governing Law</h2>

        <p>These Terms shall be governed by the laws of Hamburg, Germany, without regard to any conflict of law provisions of any jurisdiction, regardless of your country of origin or where you access the Services. You and Lingora agree to submit to the exclusive jurisdiction of the courts of Hamburg, Germany. The United Nations Convention on Contracts for the International Sale of Goods (CISG) shall not apply.</p>

        <h2>XI.&nbsp;Non-Waiver</h2>

        <p>The failure of Lingora to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.</p>

        <h2>XII.&nbsp;Assignment</h2>

        <p>12.1 You may not assign these Terms without the prior written consent of Lingora.</p>

        <p>12.2 Lingora may assign or transfer these Terms, in whole or in part without restriction, provided that such assignment shall not impair your rights under these Terms.</p>

        <h2>XIII.&nbsp;Entire Agreement and Severability of Terms</h2>

        <p>These Terms constitute the entire agreement between you and Lingora with respect to their subject matter. If any provision of these Terms is found by a court of competent jurisdiction to be invalid such provisions shall be limited or eliminated to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect.</p>

        <h2>XIV.&nbsp;Language</h2>

        <p>These Terms were originally written in English. In the event of any discrepancy between the English language version and any translated copies of these Terms, the English version shall prevail.</p>

        <h2>XV.&nbsp;Contact</h2>

        <p>15.1 Should you have any questions or queries, or if you wish to report a breach of these Terms, please contact us at <a href="mailto:stebalex@gmail.com">stebalex@gmail.com</a>&nbsp;</p>

        <p>15.2 Lingora may contact you and send you notices, including notices in relating to changes in or updates to these Terms, by email or by posting announcements in-app or on our website.</p>

        <p>Last updated on [21. August 2024]</p>

    </div>
}

export default Tos;
