import React, { Component, useEffect, useState } from "react";
import Select from 'react-select';
import { getAvailableLanguages } from "./LanguageData";


export const LanguageDropdown = ({ value, setValue }) => {
    const languageOptions = getAvailableLanguages().map(langData => {
        return { value: langData.code, label: langData.name }
    })

    const flag = () => ({
        alignItems: 'center',
        display: 'flex',

        ':before': {
            backgroundColor: "transparent",
            borderRadius: 3,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 20,
            width: 0,
        },
    });

    const styles = {
        control: (styles) => ({ ...styles, backgroundColor: 'black', color: "white", height: 55 }),
        option: (styles) => {
            return {
                ...styles,
                backgroundColor: "black",
                color: "white",
                cursor: 'default', fontSize: 15,
                height: 30,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: "#000000",
                },
            };
        },
        menuList: (styles) => ({ ...styles, height: 200, backgroundColor: "black", paddingBottom: 30 }),
        placeholder: (styles) => ({ ...styles, color: "white", lineHeight: 1.1, ...flag(), fontSize: 15 }),
        input: (styles) => ({ ...styles, color: "white", ...flag(), fontSize: "0.7em" }),
        singleValue: (styles) => ({ ...styles, ...flag(), color: "white", fontSize: 15 }),
        menuPortal: base => ({ ...base, zIndex: 9999 })
    };

    return <Select
        placeholder={"Try a Language"}
        options={languageOptions}
        styles={styles}
        value={value}
        onChange={setValue}
        isSearchable={false}
        menuPortalTarget={document.body}


    />
}