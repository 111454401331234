export const playAudio = (language, word) => {
    var audio = null;
    switch (language) {
        case "bra_portuguese":
            audio = new Audio({
                "a": require("./audio/bra_portuguese/w_a.m4a"), "amiga": require("./audio/bra_portuguese/w_amiga.m4a"),
                "dela": require("./audio/bra_portuguese/w_dela.m4a"), "deu": require("./audio/bra_portuguese/w_deu.m4a"),
                "mãe": require("./audio/bra_portuguese/w_mãe.m4a"), "Minha": require("./audio/bra_portuguese/w_Minha.m4a"),
                "para": require("./audio/bra_portuguese/w_para.m4a"), "presente": require("./audio/bra_portuguese/w_presente.m4a"),
                "um": require("./audio/bra_portuguese/w_um.m4a")
            }[word]);
            break;
        case "cantonese":
            audio = new Audio({
                "我": require("./audio/cantonese/w_我.m4a"), "有": require("./audio/cantonese/w_有.m4a"),
                "一": require("./audio/cantonese/w_一.m4a"), "隻": require("./audio/cantonese/w_隻.m4a"),
                "狗": require("./audio/cantonese/w_狗.m4a"), "同埋": require("./audio/cantonese/w_同埋.m4a"),
                "貓": require("./audio/cantonese/w_貓.m4a"), "佢哋": require("./audio/cantonese/w_佢哋.m4a"),
                "係": require("./audio/cantonese/w_係.m4a"), "好": require("./audio/cantonese/w_好.m4a"),
                "朋友": require("./audio/cantonese/w_朋友.m4a")
            }[word]);
            break;
        case "catalan":
            audio = new Audio({
                "Aprendre": require("./audio/catalan/w_aprendre.m4a"), "difícil": require("./audio/catalan/w_difícil.m4a"),
                "és": require("./audio/catalan/w_és.m4a"), "idioma": require("./audio/catalan/w_idioma.m4a"),
                "molt": require("./audio/catalan/w_molt.m4a"), "nou": require("./audio/catalan/w_nou.m4a"),
                "un": require("./audio/catalan/w_un.m4a")
            }[word]);
            break;
        case "dutch":
            audio = new Audio({
                "bloemen": require("./audio/dutch/w_bloemen.m4a"), "Deze": require("./audio/dutch/w_Deze.m4a"),
                "een": require("./audio/dutch/w_een.m4a"), "hebben": require("./audio/dutch/w_hebben.m4a"),
                "kleur": require("./audio/dutch/w_kleur.m4a"), "prachtige": require("./audio/dutch/w_prachtige.m4a")
            }[word]);
            break;
        case "eur_portuguese":
            audio = new Audio({
                "chamas": require("./audio/eur_portuguese/w_chamas.m4a"), "Como": require("./audio/eur_portuguese/w_como.m4a"),
                "conhecer": require("./audio/eur_portuguese/w_conhecer.m4a"), "em": require("./audio/eur_portuguese/w_em.m4a"),
                "Prazer": require("./audio/eur_portuguese/w_prazer.m4a"), "te": require("./audio/eur_portuguese/w_te.m4a")
            }[word]);
            break;
        case "eur_spanish":
            audio = new Audio({
                "Creo": require("./audio/eur_spanish/w_Creo.m4a"), "frutas": require("./audio/eur_spanish/w_frutas.m4a"),
                "Los": require("./audio/eur_spanish/w_Los.m4a"), "que": require("./audio/eur_spanish/w_que.m4a"),
                "son": require("./audio/eur_spanish/w_son.m4a"), "tomates": require("./audio/eur_spanish/w_tomates.m4a"),
                "verduras": require("./audio/eur_spanish/w_verduras.m4a")
            }[word]);
            break;
        case "filipino":
            audio = new Audio({
                "Ang": require("./audio/filipino/w_ang.m4a"), "ay": require("./audio/filipino/w_ay.m4a"),
                "bangko": require("./audio/filipino/w_bangko.m4a"), "isa": require("./audio/filipino/w_isa.m4a"),
                "ko": require("./audio/filipino/w_ko.m4a"), "magulang": require("./audio/filipino/w_magulang.m4a"),
                "mga": require("./audio/filipino/w_mga.m4a"), "nagtatrabaho": require("./audio/filipino/w_nagtatrabaho.m4a"),
                "ng": require("./audio/filipino/w_ng.m4a"), "sa": require("./audio/filipino/w_sa.m4a")
            }[word]);
            break;
        case "french":
            audio = new Audio({
                "autre": require("./audio/french/w_autre.m4a"), "bouteille": require("./audio/french/w_bouteille.m4a"),
                "de": require("./audio/french/w_de.m4a"), "il": require("./audio/french/w_il.m4a"),
                "moi": require("./audio/french/w_moi.m4a"), "plaît": require("./audio/french/w_plaît.m4a"),
                "Ramène": require("./audio/french/w_Ramène.m4a"), "s": require("./audio/french/w_s.m4a"),
                "une": require("./audio/french/w_une.m4a"), "vin": require("./audio/french/w_vin.m4a"),
                "vous": require("./audio/french/w_vous.m4a")
            }[word]);
            break;
        case "german":
            audio = new Audio({
                "an": require("./audio/german/w_an.m4a"), "Das": require("./audio/german/w_Das.m4a"),
                "der": require("./audio/german/w_der.m4a"), "Foto": require("./audio/german/w_Foto.m4a"),
                "hängt": require("./audio/german/w_hängt.m4a"), "schöne": require("./audio/german/w_schöne.m4a"),
                "Wand": require("./audio/german/w_Wand.m4a")
            }[word]);
            break;
        case "hindi":
            audio = new Audio({
                "वो": require("./audio/hindi/w_वो.m4a"), "क्या": require("./audio/hindi/w_क्या.m4a"),
                "है": require("./audio/hindi/w_है.m4a"),
                "एक": require("./audio/hindi/w_एक.m4a"), "पुराना": require("./audio/hindi/w_पुराना.m4a"),
                "रेडियो": require("./audio/hindi/w_रेडियो.m4a")
            }[word]);
            break;
        case "indonesian":
            audio = new Audio({
                "Aku": require("./audio/indonesian/w_aku.m4a"), "bisa": require("./audio/indonesian/w_bisa.m4a"),
                "kacamata": require("./audio/indonesian/w_kacamata.m4a"), "ku": require("./audio/indonesian/w_ku.m4a"),
                "menemukan": require("./audio/indonesian/w_menemukan.m4a"), "tidak": require("./audio/indonesian/w_tidak.m4a")
            }[word]);
            break;
        case "japanese":
            audio = new Audio({
                "います": require("./audio/japanese/w_います.m4a"), "いません": require("./audio/japanese/w_いません.m4a"),
                "が": require("./audio/japanese/w_が.m4a"), "に": require("./audio/japanese/w_に.m4a"),
                "は": require("./audio/japanese/w_は.m4a"), "彼ら": require("./audio/japanese/w_彼ら.m4a"),
                "聞こえて": require("./audio/japanese/w_聞こえて.m4a"), "見えて": require("./audio/japanese/w_見えて.m4a")
            }[word]);
            break;
        case "khmer":
            audio = new Audio({
                "សៀវភៅ": require("./audio/khmer/w_សៀវភៅ.m4a"), "កាសែត": require("./audio/khmer/w_កាសែត.m4a"),
                "ខ្ញុំ": require("./audio/khmer/w_ខ្ញុំ.m4a"), "ទីនេះ": require("./audio/khmer/w_ទីនេះ.m4a"),
                "ទីនោះ": require("./audio/khmer/w_ទីនោះ.m4a"), "នៅ": require("./audio/khmer/w_នៅ.m4a"),
                "របស់": require("./audio/khmer/w_របស់.m4a")
            }[word]);
            break;
        case "korean":
            audio = new Audio({
                "가장": require("./audio/korean/w_가장.m4a"), "달": require("./audio/korean/w_달.m4a"),
                "봄을": require("./audio/korean/w_봄을.m4a"), "사": require("./audio/korean/w_사.m4a"),
                "월은": require("./audio/korean/w_월은.m4a"), "이에요": require("./audio/korean/w_이에요.m4a"),
                "정말": require("./audio/korean/w_정말.m4a"), "제가": require("./audio/korean/w_제가.m4a"),
                "좋아하는": require("./audio/korean/w_좋아하는.m4a"), "좋아해요": require("./audio/korean/w_좋아해요.m4a")
            }[word]);
            break;
        case "malaysian":
            audio = new Audio({
                "anak": require("./audio/malaysian/w_anak.m4a"), "buku": require("./audio/malaysian/w_buku.m4a"),
                "Dia": require("./audio/malaysian/w_Dia.m4a"), "itu": require("./audio/malaysian/w_itu.m4a"),
                "kepada": require("./audio/malaysian/w_kepada.m4a"), "membaca": require("./audio/malaysian/w_membaca.m4a"),
                "nya": require("./audio/malaysian/w_nya.m4a")
            }[word]);
            break;
        case "mandarin":
            audio = new Audio({
                "东西": require("./audio/mandarin/w_东西.m4a"), "个": require("./audio/mandarin/w_个.m4a"),
                "他": require("./audio/mandarin/w_他.m4a"), "吃": require("./audio/mandarin/w_吃.m4a"),
                "在": require("./audio/mandarin/w_在.m4a"), "是": require("./audio/mandarin/w_是.m4a"),
                "男人": require("./audio/mandarin/w_男人.m4a"), "苹果": require("./audio/mandarin/w_苹果.m4a"),
                "这": require("./audio/mandarin/w_这.m4a")
            }[word]);
            break;
        case "mex_spanish":
            audio = new Audio({
                "detrás": require("./audio/mex_spanish/w_detrás.m4a"), "es": require("./audio/mex_spanish/w_es.m4a"),
                "Es": require("./audio/mex_spanish/w_es.m4a"), "eso": require("./audio/mex_spanish/w_eso.m4a"),
                "papa": require("./audio/mex_spanish/w_papa.m4a"), "que": require("./audio/mex_spanish/w_que.m4a"),
                "Qué": require("./audio/mex_spanish/w_Qué.m4a"), "tienes": require("./audio/mex_spanish/w_tienes.m4a"),
                "una": require("./audio/mex_spanish/w_una.m4a")
            }[word]);
            break;
        case "polish":
            audio = new Audio({
                "butelkę": require("./audio/polish/w_butelkę.m4a"), "do": require("./audio/polish/w_do.m4a"),
                "Jest": require("./audio/polish/w_jest.m4a"), "Możesz": require("./audio/polish/w_Możesz.m4a"),
                "otwarcia": require("./audio/polish/w_otwarcia.m4a"), "otworzyć": require("./audio/polish/w_otworzyć.m4a"),
                "tę": require("./audio/polish/w_tę.m4a"), "trudna": require("./audio/polish/w_trudna.m4a")
            }[word]);
            break;
        case "russian":
            audio = new Audio({
                "английский": require("./audio/russian/w_английский.m4a"), "английского": require("./audio/russian/w_английского.m4a"),
                "преподаю": require("./audio/russian/w_преподаю.m4a"), "учитель": require("./audio/russian/w_учитель.m4a"),
                "Я": require("./audio/russian/w_я.m4a")
            }[word]);
            break;
        case "std_arabic":
            audio = new Audio({
                "ابْنَةٌ": require("./audio/std_arabic/w_ابْنَةٌ.m4a"), "وَ": require("./audio/std_arabic/w_وَ.m4a"),
                "اِبْنٌ": require("./audio/std_arabic/w_اِبْنٌ.m4a"), "طِفْلَاْنِ": require("./audio/std_arabic/w_طِفْلَاْنِ.m4a"),
                "لَدَيَّ": require("./audio/std_arabic/w_لَدَيَّ.m4a")
            }[word]);
            break;
        case "sth_vietnamese":
            audio = new Audio({
                "bán": require("./audio/sth_vietnamese/w_bán.m4a"), "cửa hàng": require("./audio/sth_vietnamese/w_cửa hàng.m4a"),
                "Đó": require("./audio/sth_vietnamese/w_Đó.m4a"), "giày": require("./audio/sth_vietnamese/w_giày.m4a"),
                "là": require("./audio/sth_vietnamese/w_là.m4a"), "một": require("./audio/sth_vietnamese/w_một.m4a"),
                "muốn": require("./audio/sth_vietnamese/w_muốn.m4a"), "này": require("./audio/sth_vietnamese/w_này.m4a"),
                "Tôi": require("./audio/sth_vietnamese/w_Tôi.m4a"), "vô": require("./audio/sth_vietnamese/w_vô.m4a")
            }[word]);
            break;
        case "thai":
            audio = new Audio({
                "กัน": require("./audio/thai/w_กัน.m4a"), "กับ": require("./audio/thai/w_กับ.m4a"),
                "เป็น": require("./audio/thai/w_เป็น.m4a"), "ผม": require("./audio/thai/w_ผม.m4a"),
                "พวก": require("./audio/thai/w_พวก.m4a"), "เพื่อน": require("./audio/thai/w_เพื่อน.m4a"),
                "มัน": require("./audio/thai/w_มัน.m4a"), "มี": require("./audio/thai/w_มี.m4a"),
                "แมว": require("./audio/thai/w_แมว.m4a"), "หมา": require("./audio/thai/w_หมา.m4a")
            }[word]);
            break;
        case "turkish":
            audio = new Audio({
                "araba": require("./audio/turkish/w_araba.m4a"), "Ben": require("./audio/turkish/w_Ben.m4a"),
                "bir": require("./audio/turkish/w_bir.m4a"), "çok": require("./audio/turkish/w_çok.m4a"),
                "hızlı": require("./audio/turkish/w_hızlı.m4a"), "kullanıyorum": require("./audio/turkish/w_kullanıyorum.m4a"),
                "pahalı": require("./audio/turkish/w_pahalı.m4a"), "ve": require("./audio/turkish/w_ve.m4a")
            }[word]);
            break;
        case "ukrainian":
            audio = new Audio({
                "але": require("./audio/ukrainian/w_але.m4a"), "мене": require("./audio/ukrainian/w_мене.m4a"),
                "немає": require("./audio/ukrainian/w_немає.m4a"), "приготувати": require("./audio/ukrainian/w_приготувати.m4a"),
                "торт": require("./audio/ukrainian/w_торт.m4a"), "у": require("./audio/ukrainian/w_у.m4a"),
                "хочу": require("./audio/ukrainian/w_хочу.m4a"), "Я": require("./audio/ukrainian/w_я.m4a"),
                "яєць": require("./audio/ukrainian/w_яєць.m4a")
            }[word]);
            break;
        case "vietnamese":
            audio = new Audio({
                "à": require("./audio/vietnamese/w_à.m4a"), "có thể": require("./audio/vietnamese/w_có thể.m4a"),
                "đây": require("./audio/vietnamese/w_đây.m4a"), "em": require("./audio/vietnamese/w_em.m4a"),
                "Em": require("./audio/vietnamese/w_em.m4a"), "gì": require("./audio/vietnamese/w_gì.m4a"),
                "giúp": require("./audio/vietnamese/w_giúp.m4a"), "không": require("./audio/vietnamese/w_không.m4a"),
                "làm": require("./audio/vietnamese/w_làm.m4a"), "ở": require("./audio/vietnamese/w_ở.m4a"),
                "Vâng": require("./audio/vietnamese/w_Vâng.m4a"), "việc": require("./audio/vietnamese/w_việc.m4a")
            }[word]);
            break;
    }

    if (audio != null)
        audio.play();
}

export const playSentenceAudio = (langName, indexCallback) => {
    var audio = null;
    var offsets = [];
    if (langName === "filipino") {
        audio = new Audio(require("./audio/full_sentence/filipino.m4a"));
        offsets = [100, 400, 700, 1000, 1300, 1600, 1900, 2200, 2500, 2800, 3000];
    }
    if (langName === "std_arabic") {
        audio = new Audio(require("./audio/full_sentence/std_arabic.m4a"));
        offsets = [0, 400, 1200, 1500, 1600, 2000];
    }
    if (langName === "cantonese") {
        audio = new Audio(require("./audio/full_sentence/cantonese.m4a"));
        offsets = [0, 400, 700, 900, 1200, 1400, 1900, 2000, 2100, 3600, 4000, 4400, 4700, 5500];
    }
    if (langName === "catalan") {
        audio = new Audio(require("./audio/full_sentence/catalan.m4a"));
        offsets = [0, 400, 800, 1000, 1400, 1600, 2000, 2600];
    }
    if (langName === "dutch") {
        audio = new Audio(require("./audio/full_sentence/dutch.m4a"));
        offsets = [0, 500, 1000, 1200, 1500, 2000, 2500];
    }
    if (langName === "french") {
        audio = new Audio(require("./audio/full_sentence/french.m4a"));
        offsets = [0, 300, 600, 900, 1200, 1500, 1800, 2100, 2400, 2700, 3000, 3300];
    }
    if (langName === "german") {
        audio = new Audio(require("./audio/full_sentence/german.m4a"));
        offsets = [0, 300, 600, 900, 1200, 1500, 1800, 2000];
    }
    if (langName === "hindi") {
        audio = new Audio(require("./audio/full_sentence/hindi.m4a"));
        offsets = [300, 500, 800, 2000, 2200, 2500, 3000, 3500, 4000];
    }
    if (langName === "indonesian") {
        audio = new Audio(require("./audio/full_sentence/indonesian.m4a"));
        offsets = [0, 500, 800, 1200, 2000, 2500, 3000];
    }
    if (langName === "japanese") {
        audio = new Audio(require("./audio/full_sentence/japanese.m4a"));
        offsets = [0, 375, 600, 1000, 1200, 1500, 2250, 2625, 3000];
    }
    if (langName === "khmer") {
        audio = new Audio(require("./audio/full_sentence/khmer.m4a"));
        offsets = [0, 400, 800, 1100, 1400, 1800, 2200, 2600, 2800, 3100, 3400];
    }
    if (langName === "korean") {
        audio = new Audio(require("./audio/full_sentence/korean.m4a"));
        offsets = [0, 400, 800, 1300, 1700, 2100, 2500, 3500, 4000, 4500, 5000];
    }
    if (langName === "malaysian") {
        audio = new Audio(require("./audio/full_sentence/malaysian.m4a"));
        offsets = [0, 286, 572, 900, 1344, 1630, 1816, 2100];
    }
    if (langName === "mandarin") {
        audio = new Audio(require("./audio/full_sentence/mandarin.m4a"));
        offsets = [0, 233, 466, 699, 1282, 1465, 1598, 2131, 2664, 2997, 3330, 3663, 3996];
    }
    if (langName === "bra_portuguese") {
        audio = new Audio(require("./audio/full_sentence/bra_portuguese.m4a"));
        offsets = [0, 333, 666, 999, 1132, 1565, 1898, 2031, 2464, 2797];
    }
    if (langName === "eur_portuguese") {
        audio = new Audio(require("./audio/full_sentence/eur_portuguese.m4a"));
        offsets = [0, 429, 858, 1287, 1716, 2145, 2274, 3003];
    }
    if (langName === "polish") {
        audio = new Audio(require("./audio/full_sentence/polish.m4a"));
        offsets = [0, 625, 950, 1475, 2800, 3125, 3750, 4175, 5000];
    }
    if (langName === "russian") {
        audio = new Audio(require("./audio/full_sentence/russian.m4a"));
        offsets = [0, 500, 1000, 1500, 2000, 2500, 3000];
    }
    if (langName === "eur_spanish") {
        audio = new Audio(require("./audio/full_sentence/eur_spanish.m4a"));
        offsets = [0, 375, 750, 1125, 1650, 1875, 2250, 2625, 3000];
    }
    if (langName === "mex_spanish") {
        audio = new Audio(require("./audio/full_sentence/mex_spanish.m4a"));
        offsets = [0, 300, 588, 832, 1076, 1520, 2664, 3108, 3552, 3996];
    }
    if (langName === "thai") {
        audio = new Audio(require("./audio/full_sentence/thai.m4a"));
        offsets = [0, 200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000];
    }
    if (langName === "turkish") {
        audio = new Audio(require("./audio/full_sentence/turkish.m4a"));
        offsets = [0, 333, 666, 999, 1132, 1465, 1698, 1831, 2064, 2997];
    }
    if (langName === "ukrainian") {
        audio = new Audio(require("./audio/full_sentence/ukrainian.m4a"));
        offsets = [0, 444, 688, 1332, 1776, 2220, 2364, 2608, 3052, 3696];
    }
    if (langName === "vietnamese") {
        audio = new Audio(require("./audio/full_sentence/vietnamese.m4a"));
        offsets = [0, 250, 500, 750, 900, 1150, 1500, 2050, 2300, 2450, 2800, 2950, 3400];
    }
    if (langName === "sth_vietnamese") {
        audio = new Audio(require("./audio/full_sentence/sth_vietnamese.m4a"));
        offsets = [0, 273, 546, 819, 1092, 1665, 1938, 2211, 2484, 2757, 3030, 3303];
    }


    if (audio != null) {
        audio.play();
        audio.addEventListener("canplaythrough", (event) => {
            /* the audio is now playable; play it if permissions allow */
            audio.play();
            offsets.forEach((offset, index) => {
                setTimeout(() => { indexCallback(index) }, offset);
            })
        });
    }
}

//colorChannelA and colorChannelB are ints ranging from 0 to 255
const colorChannelMixer = (colorChannelA, colorChannelB) => {
    return parseInt((colorChannelA + colorChannelB) / 2);
}

const toInt = (hex) => {
    return Number("0x" + hex);
}

export const colorMixer = (rgbA, rgbB) => {
    var r = colorChannelMixer(toInt(rgbA.slice(-6, -4)), toInt(rgbB.slice(-6, -4)));
    var g = colorChannelMixer(toInt(rgbA.slice(-4, -2)), toInt(rgbB.slice(-4, -2)));
    var b = colorChannelMixer(toInt(rgbA.slice(-2)), toInt(rgbB.slice(-2)));
    var result = "#" + r.toString(16) + g.toString(16) + b.toString(16);
    return result;
}