import React, { useEffect, useState } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";
import thorough from "./images/thorough.png"

import android1 from "./images/appstores/en.png"
import ios1 from "./images/appstores/en.svg"
import android2 from "./images/appstores/cn.png"
import ios2 from "./images/appstores/cn.svg"
import android3 from "./images/appstores/th.png"
import ios3 from "./images/appstores/th.svg"
import android4 from "./images/appstores/ar.png"
import ios4 from "./images/appstores/ar.svg"
import android5 from "./images/appstores/hi.png"
import ios5 from "./images/appstores/hi.svg"

import { LanguageDropdown } from "./LanguageDropdown";
import useWindowDimensions from "./windowDimensions";

const Header = ({ language, setLanguage }) => {
  const { height, width } = useWindowDimensions();
  const [android, setAndroid] = useState(android1);
  const [ios, setIos] = useState(ios1);
  const [index, setIndex] = useState(2);

  useEffect(() => {
    if (language != null) {
      var id = document.getElementById("languages");
      if (id == null) return;
      id.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [language]);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      switch (index) {
        case 1:
          setAndroid(android1);
          setIos(ios1);
          break;
        case 2:
          setAndroid(android2);
          setIos(ios2);
          break;
        case 3:
          setAndroid(android3);
          setIos(ios3);
          break;
        case 4:
          setAndroid(android4);
          setIos(ios4);
          break;
        case 5:
          setAndroid(android5);
          setIos(ios5);
          break;
      }

      setIndex(index == 5 ? 1 : index + 1);
    }, 2500);

    return () => clearTimeout(intervalId);

  }, [index])

  const config = {
    body: "./images/icon_big.png",
    num: [0, 1],
    rps: 0.2,
    radius: [5, 40],
    life: [1.5, 3],
    v: [1, 2],
    tha: [0, 0],
    //rotate: [-0.2, 0.5],
    alpha: [0.6, 0],
    scale: [0.1, 0.2],
    position: "all", // all or center or {x:1,y:1,width:100,height:100}
    cross: "dead", // cross or bround
    random: null,  // or null,
    g: 0.2,    // gravity
    // f: [2, -1], // force
  }


  return <header id="home" style={{ height: height }}>
    <ParticlesBg type="custom" bg={true} config={config} />

    <nav id="nav-wrap">
      <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
        Show navigation
      </a>
      <a className="mobile-btn" href="#home" title="Hide navigation">
        Hide navigation
      </a>

      <ul id="nav" className="nav">
        <li className="current">
          <a className="smoothscroll" href="#home">
            Home
          </a>
        </li>

        <li>
          <a className="smoothscroll" href="#languages">
            Languages
          </a>
        </li>

        <li>
          <a className="smoothscroll" href="#features">
            Features
          </a>
        </li>

        <li>
          <a href="https://lingora.org/blog/">
            Blog
          </a>
        </li>

        <li>
          <a className="smoothscroll" href="#contact">
            Contact
          </a>
        </li>
      </ul>
    </nav>

    <div className="row banner">
      <div className="banner-text">
        <Fade bottom>
          <img src={require("./images/lingora.png")} width={300} />
        </Fade>
        <Fade bottom duration={1200}>
          <h2>Thorough.</h2>
          <img src={thorough} width={100} />
          <h3>1. done completely; with great attention to detail</h3>
          <h3>2. learning a language with Lingora</h3>
        </Fade>
        <hr />
        <Fade bottom duration={2000} >
          <ul className="social" style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: 250 }}>
              <LanguageDropdown value={language} setValue={setLanguage} />
            </div>
            <div style={{ width: 30 }} />
            <a href={"#contact"} className="button btn second-btn">Contact
            </a>
          </ul>
        </Fade>
      </div>
      <div className="appstores">
        <a href="https://play.google.com/store/apps/details?id=com.alexsteb.lingora">
          <img className="appstore" src={android} width={130} />
        </a>
        <a href="https://apps.apple.com/us/app/lingora/id6446484535">
          <img className="appstore" src={ios} width={120} />
        </a>
      </div>
    </div>

    <p className="scrolldown">
      <a className="smoothscroll" href="#languages">
        <i className="icon-down-circle"></i>
      </a>
    </p>
  </header>
}

export default Header;

