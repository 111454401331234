const Privacy = () => {
    return <div style={{ width: "80%", marginLeft: 50, marginRight: 50 }}>
        <h1><strong>Privacy Policy</strong></h1>

        <h2>I.&nbsp;General</h2>

        <p>1.1 Lingora cares about your personal data, and we are committed to safeguarding your privacy. This Privacy Policy
            details:</p>

        <p>a)&nbsp;the personal data we collect and receive about you;</p>

        <p>b)&nbsp;how we collect, use, store and share (collectively described as “process”) your personal data; and</p>

        <p>c)&nbsp;your rights and obligations in relation to your personal data.</p>

        <p>1.2 Lingora is privately owned by Alexander Stebner, resident of Alstertwiete 15, 20099, Hamburg, Germany. Lingora is
            the data controller for the purposes of the EU General Data Protection Regulation (“GDPR”) and any applicable local
            legislation (“Data Protection Laws”).</p>

        <p>1.3 Lingora provides our language learning services (the “Services”) to you subject to the terms of this Privacy
            Policy, which forms part of our Terms of Service (“Terms”, accessible at <a
                href="https://lingora.org/tos">https://lingora.org/tos</a>). By accessing or using any part of the Services, you
            represent that you have read, understand and agree to the terms of this Privacy Policy. Our Privacy Policy will also
            apply to any communications between you and Lingora.</p>

        <p>1.4 We may update this Privacy Policy from time to time to reflect changes in our data processing practices, or to
            comply with applicable law. All changes will be posted on our website, and we will indicate the date these terms
            were last revised at the bottom of the Privacy Policy. You can review the most current version of the Privacy Policy
            at any time at <a href="https://lingora.org/privacy">https://lingora.org/privacy</a>. Your continued use of the
            Services after any such changes have been made constitutes your acceptance of the new Privacy Policy.</p>

        <h2>II.&nbsp;Information We Collect and Receive</h2>

        <p>Lingora collects and receives the types of information and personal data listed in this clause 2. We do not collect
            special categories of personal data about you.</p>

        <p>(“Special categories of personal data” is defined as: i) data revealing your race or ethnicity, religious or
            philosophical beliefs, sexual orientation, political opinions and/or trade union membership; ii) information about
            your health; and ii) genetic and biometric data processed for the purpose of uniquely identifying a natural person)
        </p>

        <p>2.1 Automatically collected activity data</p>

        <p>When you use the Services, including browsing our website and engaging in language learning activities, we collect
            data about how you interact with the Services, including:</p>

        <p>a)&nbsp;Technical specifications, such as your browser type, device ID, operating system, app version, language
            settings and IP address;</p>

        <p>b)&nbsp;Navigation and learning activity, such as the pages and files you access (including previously accessed
            pages, if you visited our website via a link), the time of access, session duration, session progress,
            answers/responses submitted, and your “follows” of other users;</p>

        <p>c) Geographic data, this is limited to “fuzzy” geographic data (e.g. country, region), we do not collect your
            specific location;</p>

        <p>d) Cookies, as explained in more detail in clause 2.2 below.</p>

        <p>2.2 Cookies</p>

        <p>a)&nbsp;Cookies are small files that are created by a website or mobile app and stored on your device through your
            app or browser. Other technologies, such as pixel tagging and key-value pairs fulfil the same function. The cookies
            used by our Services include both first and third party cookies.</p>

        <p>b)&nbsp;We use first party cookies to perform useful functions that help simplify your user experience, including:
        </p>

        <p>i)&nbsp;conducting login authentication and confirming your login status;</p>

        <p>ii) remembering your preferences, such as your display language and audio playback speed settings, and the quiz
            configuration you have created within the app; and</p>

        <p>iii)&nbsp;collecting data for use in analytics.</p>

        <p>c) Third party cookies are used by third parties for advertising, retargeting, analytics, and tracking. For example,
            you may have cookies placed on your computer by third party websites that refer you to the Services, including when
            you login through your Google, Facebook or Apple account. Lingora does not share your personal data with these third
            party websites unless it is reasonably necessary for providing the Services, however, they may be able to link
            certain non-personally identifiable information we transfer to them with personal data they previously collected
            from you.</p>

        <p>d)&nbsp;If you do not want your information to be collected through the use of cookies, you can configure your
            browser to deny or accept the cookie feature. However, cookies may be necessary to provide you with certain features
            on our website and app (e.g., customized delivery of information).</p>

        <p>e)&nbsp;You can find more information about cookies and how to manage them at&nbsp;<a
            href="http://www.allaboutcookies.org/"><u>www.allaboutcookies.org</u></a></p>

        <p>f)&nbsp;The Services are not designed to respond to “do not track” signals sent by some browsers.</p>

        <p>2.3 Data received from third parties</p>

        <p>a)&nbsp;You have the option of signing up or logging in to the Services via your social media and other third party
            accounts, such as Apple, Facebook or Google. You may also post comments about the Services on third party platforms
            such as the Apple App Store or Google Play. If you choose to do so, these third party providers may also provide us
            with access to certain information that you have provided to them, such as your name, email, username, profile
            picture and device information.</p>

        <p>b) If you choose to purchase a subscription to Lingora (such as a Pro membership), we will receive your payment
            transaction information, i.e. whether the transaction was successful, and other information required for processing
            your subscription. We do not collect or record payment or financial information, such as your credit card or bank
            account details.</p>

        <p>c)&nbsp;If your membership is registered through or sponsored by an organization, they may provide us with your email
            information so that we can contact you and provide you with information on how to sign up.</p>

        <p>d)&nbsp;We will process all personal data received from third parties in accordance with our Privacy Policy. However,
            please remember that the manner in which third party providers process your information is governed solely by their
            policies.</p>

        <h2>III.&nbsp;How We Use Your Personal Data</h2>

        <p>Lingora uses your personal data for the following general purposes, and for the purposes of complying with applicable
            law and legal processes.</p>

        <p>3.1 Use as part of providing the Services</p>

        <p>It is important to us to be able to offer you the best service possible. We may therefore use the information we
            collect about you to provide, maintain, update and protect the Services, including:</p>

        <p>a) to investigate and help prevent security issues and abuse; and</p>

        <p>b) to administer and improve the Services, and for our internal operations generally, including processing
            subscriptions, troubleshooting, data analysis, testing, research, hiring, product development, and enforcement of
            our Terms.</p>

        <p>3.2 Marketing and promotions</p>

        <p>a) Lingora may also use your contact information to send you notifications regarding new services, offers and
            promotions. These marketing and promotional communications may also be customized to you based on your usage of the
            Services and other personal data we hold about you.</p>

        <p>b)&nbsp;We may also track your interaction with our marketing emails. If we do so, we will aggregate, anonymize or
            otherwise de-identify your data so that it does not reveal your personal identity.</p>

        <p>c) You may opt out of receiving these e-mails at any time by clicking on the “unsubscribe” link in the email, or by
            contacting us at <a href="mailto:stebalex@gmail.com">stebalex@gmail.com</a>.</p>

        <p>3.3 Aggregate and anonymous data</p>

        <p>a)&nbsp;We may derive aggregate data and/or anonymized data such as geographical and other demographic and/or
            statistical information from your personal data. For example, we may aggregate your activity data to calculate the
            percentage of users accessing a specific feature from a particular country. This data is not considered personal
            data in law as it does not directly or indirectly reveal your identity.</p>

        <p>b) Lingora may process aggregate or anonymous data collected through the Services for research, product development,
            marketing and any other purpose.</p>

        <p>c)&nbsp;If we combine or connect aggregated data with your personal data so that it becomes possible to identify you
            (directly or indirectly) from that data, we will treat the combined data as personal data, which will only be
            processed in accordance with this Privacy Policy.</p>

        <h2>IV.&nbsp;Sharing Your Information with Third Parties</h2>

        <p>Lingora shares your personal data only when it is legally required, or permitted by you. We do not share your
            information with third party advertisers.</p>

        <p>Where we share your personal data with third parties, we ensure that there are contractual provisions in place that
            provide you with at least the same protection as this Privacy Policy.</p>

        <p>In the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some
            or all of our assets or operations, your personal data will be disclosed and transferred to Lingora’s successor or
            buyer.</p>

        <h2>V.&nbsp;Retention, Storage and Transfer of Your Personal Data</h2>

        <p>5.1 Retention of your personal data</p>

        <p>Lingora will retain your personal data for such time as required to fulfil our contractual and legal obligations, and
            any other permissible purpose for which we have collected it. Anonymized, aggregated and other data that does not
            identify you personally, such as your activity data, may be retained indefinitely and shared with third parties in
            any manner. You can request that Lingora delete your data by emailing us at <a
                href="mailto:stebalex@gmail.com">stebalex@gmail.com</a>, or alternatively you can delete your data by deleting
            your account through the links provided in-app and in your web account.</p>

        <p>5.2 Storage and security of your personal data</p>

        <p>Lingora stores your personal data on secure servers with up-to-date firewall protection that are held in secure
            facilities. In addition, Lingora also implements commercially appropriate and industry standard administrative,
            technical and physical safeguards to protect the confidentiality and security of your personal data, and to prevent
            unauthorized access. However, as transmission via the internet is not 100% secure, we cannot guarantee complete
            protection of your data. Given sufficient resources, a determined attacker could defeat our safeguards and gain
            access to the data we seek to protect. If you have reason to believe that your interaction with us is no longer
            secure, please immediately notify us by emailing <a href="mailto:stebalex@gmail.com">stebalex@gmail.com</a>. </p>

        <p>5.3 International transfers of personal data</p>

        <p>We may at times need to transfer your personal data to countries in which the applicable laws may not offer the same
            level of data protection as the laws of your home country. In such cases, we ensure that contractual provisions with
            third party data processors and any other third party agents we use provide you with at least the same protection as
            this Privacy Policy.</p>

        <h2>VI.&nbsp;Your Rights in Relation to the Personal Data We Hold</h2>

        <p>6.1 Access, amendment and deletion rights</p>

        <p>a)&nbsp;You have the following rights in relation to the personal data we hold about you, unless provided otherwise
            by local law:</p>

        <p>i)&nbsp;to update, amend, and/or correct your information (which you can do through your account settings) or request
            us to do so;</p>

        <p>ii)&nbsp;to request access to, or erasure of, the personal data we hold about you;</p>

        <p>iii)&nbsp;to object to us processing personal data relating to you, or to request that we restrict such processing;
        </p>

        <p>iv) to withdraw your consent to some or all of Lingora’s processing of your personal data, for example, you may
            request that we not use your personal data to send you marketing or promotional material (however, this will not
            affect the lawfulness of any processing carried out before you withdraw your consent);</p>

        <p>v) to export, or request a copy of, the personal data you have provided to us in a format that can be transferred
            electronically to a third party; and</p>

        <p>b)&nbsp;Please note that some of these rights may not be absolute. For example, we may refuse a request if it meant
            that we would no longer be able to meet our contractual obligations to you, or if it would prevent us from meeting
            our legal obligations. We will keep you informed of the actions we are able to take when you make your request.</p>

        <p>c) Requests should be sent to <a href="mailto:stebalex@gmail.com">stebalex@gmail.com</a>. As a security measure, we
            may need to request specific information from you to help us confirm your identity and ensure your right to access
            your personal data (or to exercise any of your other rights).</p>

        <p>d)&nbsp;You do not have to pay a fee to access your personal data (or to exercise any of the other rights). However,
            we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may
            refuse to comply with your request in those circumstances.</p>

        <p>6.2 Individuals in the United Kingdom and European Economic Area</p>

        <p>If you are a resident of the United Kingdom (UK) or the European Economic Area (EEA) and you believe that we have
            processed your personal data in a manner inconsistent with your privacy rights, you have the right to lodge a GDPR
            complaint with the relevant supervisory authority:</p>

        <p>a)&nbsp;UK residents can find contact details for the Information Commissioner’s Office at <a
            href="https://ico.org.uk/global/contact-us/"><u>https://ico.org.uk/global/contact-us/</u></a>; and</p>

        <p>b)&nbsp;a list of supervisory authorities in the EEA and their contact details can be accessed at <a
            href="https://edpb.europa.eu/about-edpb/about-edpb/members_en"><u>https://edpb.europa.eu/about-edpb/about-edpb/members_en</u></a>.
        </p>

        <p>However, we would appreciate the chance to address your concerns, and ask that you kindly contact Lingora at <a
            href="mailto:stebalex@gmail.com">stebalex@gmail.com</a> prior to lodging a complaint.</p>

        <p>6.3 California Residents</p>

        <p>a)&nbsp;In addition to the rights listed in clause 6.1 above, California residents also have the following rights
            under the <em>California Consumer Privacy Act 2018</em>&nbsp;(“CCPA”):</p>

        <p>i)&nbsp;To know, in respect of the preceding 12 months</p>

        <p>A.&nbsp;what personal data we have collected about you;</p>

        <p>B.&nbsp;where we collected the personal data from;</p>

        <p>C.&nbsp;what (if any) personal data we disclose or share with third parties;</p>

        <p>D.&nbsp;the categories of third parties with whom we shared your personal data (if any); and</p>

        <p>E.&nbsp;whether, and what categories of, personal data (if any) was shared for business or commercial purposes, and
            what those business purposes were.</p>

        <p>This information is contained in clauses 2 to 5 of this Privacy Policy, and may also be requested from Lingora.</p>

        <p>ii)&nbsp;To update, amend, correct and/or delete your information (which you can do through your account settings) or
            request us to do so;</p>

        <p>iii)&nbsp;To opt-out of having your personal information shared with certain third parties; and</p>

        <p>iv)&nbsp;To not be discriminated against for exercising your CCPA privacy rights.</p>

        <p>b) California residents can submit a request by emailing us at <a
            href="mailto:stebalex@gmail.com">stebalex@gmail.com</a>. Requestors will need to provide us with certain
            personal data for us to match with our records in order to verify your identity and residency. The personal
            information that we use to verify your identity and residency will not be used for any other purpose.</p>

        <p>c) We do not discriminate against California residents who exercise their CCPA privacy rights. However, please note
            that some of these rights may not be absolute. For example, we may refuse a request if it meant that we would no
            longer be able to meet our contractual obligations to you, or if it would prevent us from meeting our legal
            obligations. We will keep you informed of the actions we are able to take when you make your request.</p>

        <h2>VII. Third party Websites and Links</h2>

        <p>The Services contain links to other platforms, websites, and applications operated by third parties, such as links
            that allow you to connect with us on social media. The inclusion of such links does not imply Lingora’s endorsement
            of the content on such sites, or of their owners or operators.</p>

        <p>Any time you click on links which take you to third party websites, you will be subject to the third parties’ privacy
            policies. Lingora does not control these platforms, websites or applications, and is not responsible for their
            content, privacy policies, or their collection and use of personal data.</p>

        <p>We encourage you to review the privacy policies of any website, application, or platform you use outside of our
            Services before providing any information to or through them.</p>

        <h2>VIII. Contact Us</h2>

        <p>If you have any questions or concerns about this Privacy Policy, our privacy practices, or if you wish to exercise
            your individual rights, please contact us by emailing <a href="mailto:stebalex@gmail.com">stebalex@gmail.com</a>.
        </p>

        <p>Last updated: [21 August 2024]</p>
    </div>
}
export default Privacy;
