import React, { Component, useState } from "react";
import { Fade, Slide } from "react-reveal";

const Contact = () => {

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Something went wrong. Please try again later.");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleChange = (field, e) => {
    setShowError(false);
    switch (field) {
      case "name": setName(e.target.value); break;
      case "email": setEmail(e.target.value); break;
      case "subject": setSubject(e.target.value); break;
      case "message": setMessage(e.target.value); break;
    }
  }

  const checkValues = () => {
    if (name === "") {
      setErrorMessage("Please enter your name!");
      setShowError(true);
      return;
    }

    if (email === "") {
      setErrorMessage("Please enter your email address!");
      setShowError(true);
      return;
    }

    if (message === "") {
      setErrorMessage("Please enter a message!");
      setShowError(true);
      return;
    }

    fetch('https://eoneoservers.digital:8888/lingora_send_email', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: name, email: email, subject: subject, message: message })
    }).then(result => {
      result.text().then(text => {
        if (text == "OK") {
          setShowSuccess(true);
        } else {
          setShowError(true);
          setErrorMessage("Couldn't send message. Please try again later.")
        }
      })

    })
      .catch(e => setShowError(true));
  }

  return <section id="contact" style={{ alignItems: "center", alignContent: "center", alignSelf: "center" }}>
    <Fade bottom duration={1000}>
      <div className="row section-head">
        <div className="two columns header-col">
          <h1>
            <span>Get In Touch.</span>
          </h1>
        </div>

        <div className="ten columns">
          <p className="lead">{"Send a Message to Lingora!"}</p>
        </div>
      </div>
    </Fade>

    <div className="row">
      <Slide left duration={1000}>
        <div className="eight columns">
          <fieldset>
            <div>
              <label htmlFor="contactName">
                Name <span className="required">*</span>
              </label>
              <input
                id="contactName"
                type="text"
                defaultValue=""
                size="35"
                onChange={e => handleChange("name", e)}
              />
            </div>

            <div>
              <label htmlFor="contactEmail">
                Email <span className="required">*</span>
              </label>
              <input
                id="contactEmail"
                type="text"
                defaultValue=""
                size="35"
                onChange={e => handleChange("email", e)}
              />
            </div>

            <div>
              <label htmlFor="contactSubject">Subject</label>
              <input
                id="contactSubject"
                type="text"
                defaultValue=""
                size="35"
                onChange={e => handleChange("subject", e)}
              />
            </div>

            <div>
              <label htmlFor="contactMessage">
                Message <span className="required">*</span>
              </label>
              <textarea
                id="contactMessage"
                cols="50"
                rows="15"
                onChange={e => handleChange("message", e)}
              ></textarea>
            </div>

            <div style={{ display: "flex" }}>
              <button className="submit" onClick={checkValues}>Submit</button>
              <span id="image-loader">
                <img alt="" src="images/loader.gif" />
              </span>
            </div>
          </fieldset>


          {showError ?
            <div id="message-warning">{errorMessage}</div> : <></>}
          {showSuccess ?
            <div id="message-success">
              <i className="fa fa-check"></i>Your message was sent, thank you!
              <br />
            </div> : <></>}
        </div>
      </Slide>
    </div>
  </section>
}

export default Contact;
