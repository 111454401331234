import ParticlesBg from "particles-bg";
import React, { Component, useEffect, useRef, useState } from "react";
import ReactPlayer from 'react-player'
import VisibilitySensor from 'react-visibility-sensor';
import useWindowDimensions from "./windowDimensions";

import android from "./images/appstores/en.png"
import ios from "./images/appstores/en.svg"

const Features = () => {
  const { height, width } = useWindowDimensions();


  return <section id="features" style={{ height: "fit-content" }}>

    <center>
      <div style={{ width: "80%", alignSelf: "center", alignContent: "center", marginTop: 70 }}>


        <div className="blurry-container" style={{ width: "100%" }}>
          <p />
          <h1>Features:</h1>
          <p />
          <p>🌍 Available in 17 languages. <div class="explanation">Coming soon: Arabic, Filipino, Hindi, Japanese, Khmer, Russian, and Ukrainian</div></p>

          <p>📚 500 Lessons in 50 Units: <div class="explanation">Lessons that gradually elevate your skills from zero to a lower beginner level.</div></p>

          <p>🗣️ Sentence-Centric Learning: <div class="explanation">Each lesson centers around a single sentence, explained from all angles.</div></p>

          <p>🧠 Dynamic Practice: <div class="explanation">A variety of engaging vocabulary games and quiz types.</div></p>

          <p>📖 Build Your Vocabulary: <div class="explanation">Built-in vocabulary trainer for focused practice.</div></p>

          <p>✍️ Script Mastery: <div class="explanation">Practice foreign scripts the step-by-step script trainer.</div></p>

          <p>📓 Grammar Reinforcement: <div class="explanation">Practice grammar through a variety of grammar quizzes.</div></p>

          <p>🎵 Tune into Tones: <div class="explanation">Various tone-based quizzed for tonal languages.</div></p>

          <div className="appstores">
            <a href="https://play.google.com/store/apps/details?id=com.alexsteb.lingora">
              <img className="appstore" src={android} width={130} />
            </a>&nbsp;&nbsp;
            <a href="https://apps.apple.com/us/app/lingora/id6446484535">
              <img className="appstore" src={ios} width={120} />
            </a>
          </div>
        </div>
      </div>
    </center>
  </section>;
}

class Testimonials extends Component {
  render() {
    if (!this.props.data) return null;

    const features = this.props.data.features.map(function (features) {
      return (
        <li key={features.user}>
          <blockquote>
            <p>{features.text}</p>
            <cite>{features.user}</cite>
          </blockquote>
        </li>
      );
    });

    return (
      <section id="features">
        <div className="text-container">
          <div className="row">
            <div className="two columns header-col">
              <h1>
                <span>Client Testimonials</span>
              </h1>
            </div>

            <div className="ten columns flex-container">
              <ul className="slides">{features}</ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Features;
